import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StepModel } from '../models/step.model';
import { ChangeDetectorRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StepperService {

  private steps: StepModel[] = Array.from({ length: 4 }, (_, i) => ({
    number: i + 1,
    completed: false,
  }));


  private currentStepSubject = new BehaviorSubject<number>(1);
  currentStep$: Observable<number> = this.currentStepSubject.asObservable();

  constructor(
    
  ) { }


  getSteps(): StepModel[] {
    return this.steps;
  }

  getCurrentStep(): number {
    return this.currentStepSubject.value;
  }

  nextStep(): void {
    this.steps[this.currentStepSubject.value - 1].completed = true;
    this.currentStepSubject.next(this.currentStepSubject.value + 1);
  }

  next2Step(): void {
    this.steps[this.currentStepSubject.value - 1].completed = true;
    this.currentStepSubject.next(this.currentStepSubject.value + 2);
    
  }

  prevStep(): void {
    this.currentStepSubject.next(this.currentStepSubject.value - 1);
  }
}
