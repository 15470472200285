<div class="container">
    <div class="content">
        <h3>Application approval</h3>
        <form (ngSubmit)="onSubmit()" [formGroup]="userForm" autocomplete="off">
            <div class="">
                <div class="form-input">
                    <label>Change Status</label>
                    <div>
                        <select name="applicationApprovalId" id="applicationApprovalId"
                            formControlName="applicationApprovalId" required>
                            <option *ngFor="let op of approvalStatuses" [value]="op.id">{{op.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-input">
                    <label>Comment</label>
                    <div>
                        <textarea name="comment" formControlName="comment" id="comment" required
                            cols="30" rows="10"></textarea>
                    </div>
                </div>
            </div>
            <div class="step-panel_footer">
                <button>Cancel</button>
                <button type="submit">Submit</button>
            </div>
        </form>
    </div>
</div>