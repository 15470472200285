import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { jwtDecode } from 'jwt-decode';
import { ApplicationService } from 'src/app/application/services/application.service';
import { SessionService } from 'src/app/authentication-services/session.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-occupancy-inspection',
  templateUrl: './occupancy-inspection.component.html',
  styleUrls: ['./occupancy-inspection.component.scss']
})
export class OccupancyInspectionComponent {
  @Input() inputData: any;
  @Output() backToParent = new EventEmitter();
  preventionForm!: FormGroup;
  projectDetails: any = {};
  paramsId: any = '';
  page = 1;
  pageSize = 10;
  startIndex = 0;
  endIndex = 9;
  totalRecords = 0;
  collectionSize = 0;
  content?: any;
  total!: number;
  fullList: any[] = [];
  filteredList: any[] = [];
  lists: any[] = [];
  isNewInspection: boolean = false;
  currentUser: any;
  fileData: any = {};

  yesOrNo: any[] = [
    { id: 'Yes', name: 'Yes' },
    { id: 'No', name: 'No' },
    { id: 'Not applicable', name: 'Not applicable' }
  ];

  constructor(private fb: FormBuilder,
    public applicationService: ApplicationService,
    private sessionService: SessionService,) {
    this.currentUser = this.sessionService.getSession();
    this.currentUser.userId = (jwtDecode(this.currentUser.data.token.access_token) as any).UserId;

  }

  closeList() {
    this.backToParent.emit();
  }


  ngOnDestroy() {
    this.applicationService.searchTerm = '';
  }


  getFileToSave(event: any) {
    this.fileData = event;
  }

  ngOnInit(): void {
    console.log(this.inputData);
    this.preventionForm = this.fb.group({
      fireExtinguishersInResidence: ['', Validators.required],
      fireBlanketInKitchen: ['', Validators.required],
      twoExitsPerResidence: ['', Validators.required],
      emergencyExit: ['', Validators.required],
      fireAlarmOnEachFloor: ['', Validators.required],
      smokeDetectorsSprinklers: ['', Validators.required],
      hoseReelsEachFloor: ['', Validators.required],
      lightningArrestor: ['', Validators.required],
      fireExtinguishersEvery30m: ['', Validators.required],
      exitSignsEachFloor: ['', Validators.required],
      emergencyExitEachFloor: ['', Validators.required],
      floorPlanEachLevel: ['', Validators.required],
      signForElevators: ['', Validators.required],
      landingSpaceHelicopter: ['', Validators.required],
      evacuationPlan: ['', Validators.required],
      cctvCameras: ['', Validators.required],
      metalDetectors: ['', Validators.required],
      luggageScanners: ['', Validators.required],
      emergencyPhoneNumbers: ['', Validators.required],
      fireHydrantNearby: ['', Validators.required],
      evacuationPlanSecurity: ['', Validators.required],
      securityManagerStaff: ['', Validators.required],
      restrictedAccess: ['', Validators.required],
      insuranceBuilding: ['', Validators.required],
      comments: [''],
      applicationId: [this.inputData.applicationDetail.id, Validators.required],
      userId: [this.currentUser.userId, Validators.required]
    });


    // this.applicationService.findAllWithPath(environment.applicationUrl + 'application/permitType/code/search?search=NCP')
    //   .subscribe(

    //     data => {
    //       this.applicationService.findAllWithPath(environment.applicationUrl + 'application/application/' + this.inputData.applicationDetail.projects.id + '/permit-type/' +
    //         data.items[0].id
    //       ).subscribe(
    //         dataItem => {
    //           this.projectDetails = dataItem[0];
    //           console.log(this.projectDetails);
    //         }
    //       )
    //     }

    //   )
  }


  loadList() {
    this.applicationService.findAllWithPath(environment.applicationUrl + 'application/inspection/occupancy/ByApplication/' + this.inputData.applicationDetail.id
    ).subscribe(
      dataItem => {
        this.lists = dataItem;
      }
    )
  }


  getPremiumData() {
    this.filterAndPaginate();
  }

  filterAndPaginate() {
    const filtered = this.fullList.filter(item => this.applyFilter(item));
    this.filteredList = filtered;
    this.totalRecords = filtered.length;
    this.startIndex = (this.page - 1) * this.pageSize + 1;
    this.endIndex = (this.page - 1) * this.pageSize + this.pageSize;
    if (this.endIndex > this.totalRecords) {
      this.endIndex = this.totalRecords;
    }
    this.lists = filtered.slice(this.startIndex - 1, this.endIndex);
  }

  applyFilter(item: any): boolean {
    if (!this.applicationService.searchTerm) return true;
    const term = this.applicationService.searchTerm.toLowerCase();
    return Object.values(item).some(val =>
      String(val).toLowerCase().includes(term)
    );
  }


  onSearchTermChange(term: string): void {
    this.applicationService.searchTerm = term;
    this.filterAndPaginate();
  }

  cancel() {
    this.isNewInspection = !this.isNewInspection;
  }

  newInspection(event: any) {
    this.cancel()
    if (event) {

    }
  }

  onSubmit() {
    let formData = new FormData();
    formData.append("fireExtinguishersInResidence", this.preventionForm.value.fireExtinguishersInResidence + '');
    formData.append("fireBlanketInKitchen", this.preventionForm.value.fireBlanketInKitchen + '');
    formData.append("twoExitsPerResidence", this.preventionForm.value.twoExitsPerResidence + '');
    formData.append("emergencyExit", this.preventionForm.value.emergencyExit + '');
    formData.append("fireAlarmOnEachFloor", this.preventionForm.value.fireAlarmOnEachFloor + '');
    formData.append("smokeDetectorsSprinklers", this.preventionForm.value.smokeDetectorsSprinklers + '');
    formData.append("hoseReelsEachFloor", this.preventionForm.value.hoseReelsEachFloor + '');
    formData.append("lightningArrestor", this.preventionForm.value.lightningArrestor + '');
    formData.append("fireExtinguishersEvery30m", this.preventionForm.value.fireExtinguishersEvery30m + '');
    formData.append("exitSignsEachFloor", this.preventionForm.value.exitSignsEachFloor + '');
    formData.append("emergencyExitEachFloor", this.preventionForm.value.emergencyExitEachFloor + '');
    formData.append("floorPlanEachLevel", this.preventionForm.value.floorPlanEachLevel + '');
    formData.append("signForElevators", this.preventionForm.value.signForElevators + '');
    formData.append("landingSpaceHelicopter", this.preventionForm.value.landingSpaceHelicopter + '');
    formData.append("evacuationPlan", this.preventionForm.value.evacuationPlan + '');
    formData.append("cctvCameras", this.preventionForm.value.cctvCameras + '');
    formData.append("metalDetectors", this.preventionForm.value.metalDetectors + '');
    formData.append("luggageScanners", this.preventionForm.value.luggageScanners + '');
    formData.append("emergencyPhoneNumbers", this.preventionForm.value.emergencyPhoneNumbers + '');
    formData.append("fireHydrantNearby", this.preventionForm.value.fireHydrantNearby + '');
    formData.append("evacuationPlanSecurity", this.preventionForm.value.evacuationPlanSecurity + '');
    formData.append("securityManagerStaff", this.preventionForm.value.securityManagerStaff + '');
    formData.append("restrictedAccess", this.preventionForm.value.restrictedAccess + '');
    formData.append("insuranceBuilding", this.preventionForm.value.insuranceBuilding + '');
    formData.append("comments", this.preventionForm.value.comments + '');
    formData.append("userId", this.currentUser.userId + '');
    formData.append("applicationId", this.inputData.applicationDetail.id + '');

    const fileList: FileList = this.fileData.file;
    if (fileList && fileList[0]) {
      const file: File = fileList[0];
      this.fileData.fileNameDisplay = file?.name
      formData.append('file', file, file?.name);
    }

    this.applicationService.saveAssetWithPathFormData(formData, environment.applicationUrl + 'application/inspection/occupancy')
      // this.applicationService.saveAssetWithPath(this.preventionForm.value, environment.applicationUrl + 'application/inspection/occupancy')
      .subscribe(
        data => {
          this.cancel();
          this.loadList();
        }, error => { })
  }
}
