<div class="app-gen appl-details">
    <div class="app-gen-header genvline">
        <section class="genline">
            <div>
                <span class="mgnb" aria-label="header tittle">Parcel location</span>
            </div>
            <div class="btns">
                <button type="button" class="kbk-btn kbk-btn-sec" (click)="close()">Back</button>
            </div>
        </section>
        <div class="step-panel_body">
            <div class="result">
                <div class="result-item">
                    <h3 class="mgnb">Parcel Details</h3>
                    <div>
                        <div class="form-out">
                            <label>Provice</label>
                            <span>{{applicationDetail?.provinceName}}</span>
                        </div>
                        <div class="form-out">
                            <label>District</label>
                            <span>{{applicationDetail?.districtName}}</span>
                        </div>
                        <div class="form-out">
                            <label>Sector</label>
                            <span>{{applicationDetail?.sectorName}}</span>
                        </div>
                        <div class="form-out">
                            <label>Cell</label>
                            <span>{{applicationDetail?.cellName}}</span>
                        </div>
                        <div class="form-out">
                            <label>Village</label>
                            <span>{{applicationDetail?.villageName}}</span>
                        </div>
                    </div>
                </div>
                <div class="result-map">
                    <h3 class="mgnb"></h3>
                    <div>
                        <label>Coordinates</label>
                        <div class="map-wrapper">
                            <google-map [options]="mapOptions">
                                <map-marker [position]="marker.position"></map-marker>
                            </google-map>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="step-panel_body">
    <div class="result">
        <div class="result-item">
            <h3 class="mgnb">Parcel Details</h3>
            <div>
                <div class="form-out">
                    <label>Apply for</label>
                    <div class="kbk-x-s sp-xs">
                        <span>{{applicationDetail?.applicationName }}</span>
                    </div>
                </div>
                <div class="form-out">
                    <label>Provice</label>
                    <span>{{applicationDetail?.parcelLocation?.province?.provinceName}}</span>
                </div>
                <div class="form-out">
                    <label>District</label>
                    <span>{{applicationDetail?.parcelLocation?.district?.districtName}}</span>
                </div>
                <div class="form-out">
                    <label>Sector</label>
                    <span>{{applicationDetail?.parcelLocation?.sector?.sectorName}}</span>
                </div>
                <div class="form-out">
                    <label>Cell</label>
                    <span>{{applicationDetail?.parcelLocation?.cell?.cellName}}</span>
                </div>
                <div class="form-out">
                    <label>Village</label>
                    <span>{{applicationDetail?.parcelLocation?.village?.villageName}}</span>
                </div>
            </div>
        </div>
        <section class="genline">
            <div>
                <div class="result-map">
                    <h3 class="mgnb">Parcel location</h3>
                    <div class="btns">
                        <button type="button" class="kbk-btn kbk-btn-sec">Back</button>
                    </div>
                    <div>
                        <label>Coordinates</label>
                        <div class="map-wrapper">
                            <google-map [options]="mapOptions">
                                <map-marker [position]="marker.position"></map-marker>
                            </google-map>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div> -->