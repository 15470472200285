import { Component } from '@angular/core';

@Component({
  selector: 'app-verifiy-application',
  templateUrl: './verifiy-application.component.html',
  styleUrls: ['./verifiy-application.component.scss']
})
export class VerifiyApplicationComponent {

}
