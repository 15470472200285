import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { ApplicationService } from 'src/app/application/services/application.service';
import { SessionService } from 'src/app/authentication-services/session.service';
import { NgbdAdvancedSortableHeader } from 'src/app/shared/directives/sortable.directive';
import { NOTIFICATION_COLOR, UtilService } from 'src/app/shared/services/util.service';
import { UserMgtService } from 'src/app/user-management/services/user-mgt.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-assign-application-to-user',
  templateUrl: './assign-application-to-user.component.html',
  styleUrls: ['./assign-application-to-user.component.scss']
})
export class AssignApplicationToUserComponent {
  @Input() inputData: any;
  @Output() backToParent = new EventEmitter();
  total!: number;
  @ViewChildren(NgbdAdvancedSortableHeader)
  headers!: QueryList<NgbdAdvancedSortableHeader>;
  page = 1;
  pageSize = 20;
  startIndex = 0;
  endIndex = 9;
  totalRecords = 0;
  collectionSize = 0;
  content?: any;
  econtent: any = {};
  lists: any[] = [];
  masterSelected!: boolean;
  currentUser: any;


  constructor(
    public userService: UserMgtService,
    private utilService: UtilService,
    private applicationService: ApplicationService,
    private sessionService: SessionService
  ) {
    this.currentUser = this.sessionService.getSession();
    this.currentUser.userId = (jwtDecode(this.currentUser.data.token.access_token) as any).UserId;
    this.loadList();
  }
  ngOnDestroy() {
    this.userService.searchTerm = '';
  }


  loadList() {
    // this.userService
    //   .findAllWithPath(environment.authUrl + "user-management/users")
    this.userService
      .findAllWithPath(environment.authUrl + "auth/user/" + this.currentUser.data.user.agency.id)
      .subscribe((data) => {
        this.lists = this.filterUsers(data);
        this.content = this.lists;
        setTimeout(() => {
          document.getElementById("elmLoader")?.classList.add("d-none");
        }, 1200);
        this.collectionSize = this.lists.length;
        this.total = this.lists.length;
        this.totalRecords = this.lists.length;
        this.startIndex = (this.page - 1) * this.pageSize + 1;
        this.endIndex = (this.page - 1) * this.pageSize + this.pageSize;
        if (this.endIndex > this.totalRecords) {
          this.endIndex = this.totalRecords;
        }
        this.lists = this.lists.slice(
          this.startIndex - 1,
          this.endIndex
        );
      });
  }



  filterUsers(allUsers: any) {
    // allUsers.forEach((element: any) => {
    //   if (element.role.code === VALUE.ADMIN_ROLE_CODE || element.role.code === VALUE.STAFF_ROLE_CODE ||
    //     element.role.code === VALUE.STAFF_REVIEWER_ROLE_CODE) {
    //     this.lists.push(element);
    //   }
    // });
    // return this.lists;
    return allUsers;
  }

  getPremiumData() {
    this.lists = this.content
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  checkedValGet: any[] = [];
  checkUncheckAll(ev: any) {
    this.lists.forEach((x: { state: any }) => (x.state = ev.target.checked));
    var checkedVal: any[] = [];
    var result;
    for (var i = 0; i < this.lists.length; i++) {
      if (this.lists[i].state == true) {
        result = this.lists[i];
        checkedVal.push(result);
      }
    }

    console.log(checkedVal);
    this.checkedValGet = checkedVal;
    checkedVal.length > 0
      ? ((
        document.getElementById("remove-actions") as HTMLElement
      ).style.display = "block")
      : ((
        document.getElementById("remove-actions") as HTMLElement
      ).style.display = "none");
  }


  onCheckboxChange(e: any) {
    var checkedVal: any[] = [];
    var result;
    for (var i = 0; i < this.lists.length; i++) {
      if (this.lists[i].state == true) {
        result = this.lists[i];
        checkedVal.push(result);
      }
    }
    this.checkedValGet = checkedVal;
    checkedVal.length > 0
      ? ((
        document.getElementById("remove-actions") as HTMLElement
      ).style.display = "block")
      : ((
        document.getElementById("remove-actions") as HTMLElement
      ).style.display = "none");
  }



  ngOnInit() {
    console.log(this.inputData);
    // this.applicationService.findAllWithPath(environment.applicationUrl + 'application/reviewersOnApplication/' + this.inputData.id)
    //   .subscribe(
    //     data => {
    //       console.log(data);
    //     }, error => {

    //     })
  }




  submit(event: any) {
    if (this.checkedValGet.length === 0) {
      this.utilService.showNotification(NOTIFICATION_COLOR.error, "Atleast select one user to proceed", "bottom", "center")
    } else {
      let dataArray: any[] = []
      this.checkedValGet.forEach(element => {
        dataArray.push(element.id);
      });
      this.applicationService.saveAssetWithPath(dataArray, environment.applicationUrl + 'application/assignUsersFoReview/' + this.inputData.id + '/assign-users')
        .subscribe(
          data => {
            this.utilService.showNotification(NOTIFICATION_COLOR.success, "Application assigned to reviewer successfully", "bottom", "center");
            this.backToParent.emit(true);
          }
        )
    }
  }


  close() {
    this.backToParent.emit(false);
  }
}
