<div class="app-dash" *ngIf="!isNewInspection">
    <div class="container">
        <div class="app-main">
            <div class="app-welnonte">
                <div class="app-welnote_dtails">
                </div>
            </div>
            <div class="app-lists">
                <div class="app-tblist">
                    <div class="app-tblist_title">
                        <span class="hder" aria-label="header tittle">Inspections</span>
                        <div class="btns">
                            <div class="form-input clear-m w-aut">
                                <div class="form-input_search">
                                    <input type="text" name="searchTerm" [(ngModel)]="applicationService.searchTerm"
                                        (ngModelChange)="onSearchTermChange($event)"
                                        placeholder="Search for something...">
                                    <button type="button" class="btn">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <g data-name="Layer 2">
                                                <g data-name="search">
                                                    <rect width="24" height="24" opacity="0" />
                                                    <path
                                                        d="M20.71 19.29l-3.4-3.39A7.92 7.92 0 0 0 19 11a8 8 0 1 0-8 8 7.92 7.92 0 0 0 4.9-1.69l3.39 3.4a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42zM5 11a6 6 0 1 1 6 6 6 6 0 0 1-6-6z" />
                                                </g>
                                            </g>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <a class="kbk-btn kbk-btn-sec brtn-edth" data-bs-toggle="modal" id="create-btn"
                                data-bs-target="#showModal" (click)="closeList()">Close</a>
                            <a class="kbk-btn kbk-btn-main brtn-edth" data-bs-toggle="modal" id="create-btn"
                                data-bs-target="#showModal" (click)="newInspection('')">New</a>
                        </div>
                    </div>
                    <ul class="tblist" *ngIf="lists.length > 0">
                        <li class="tblist-item" *ngFor="let li of lists">
                            <div class="tblist-item_icon">
                                <img src="assets/ikons/colored/ikon-file-blue.svg" alt="" />
                            </div>
                            <div class="tblist-item_dt">
                                <span>
                                    <span class="ttl">UPI</span> {{li.UPI}}</span>
                                <span>
                                    <span class="ttl">Reason</span> {{li.reason}}</span>
                            </div>
                            <div class="tblist-item_dt txt-l">
                                <span>
                                    <span class="ttl">Certificate</span> {{li?.certificate?.certificateNumber}} </span>
                                <span>
                                    <span class="ttl">Status</span> {{li?.siteStatus?.name}}</span>
                            </div>
                            <!-- <div class="tblist-item_xcn" *ngIf="lists.length > 0">
                                <a class="kbk-link-btn hs-tp" data-kbk-tooltip="Related Application" id="create-btn">
                                    Edit </a>
                            </div> -->
                        </li>
                    </ul>
                    <div class="pagnation" *ngIf="lists.length > 0">
                        <div class="pagnation-item">
                            <div class="pagnation-dt" id="tickets-table_info" role="status" aria-live="polite">
                                <span class="ent">{{totalRecords}}</span> <span class="cur">{{startIndex}} -
                                    {{endIndex}}</span>
                            </div>
                        </div>
                        <div class="pagnation-item">
                            <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize"
                                (pageChange)="getPremiumData();">
                            </ngb-pagination>
                        </div>
                    </div>
                    <ul class="tblist" *ngIf="lists.length === 0">
                        <li class="tblist-item">
                            <h2 *ngIf="lists.length === 0" id="exampleModalLabel" class="no-rcrds-found">No records
                                found</h2>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="app-gen appl-details" *ngIf="isNewInspection">
    <div class="app-gen-header genvline">
        <section class="genline">
            <div>
                <span class="hder" aria-label="header tittle">Inspection</span>
                <!-- <span class="hder-sm" aria-label="header tittle">1. Site identification and Contractor details</span> -->
            </div>
        </section>
        <form [formGroup]="preventionForm" (ngSubmit)="onSubmit()">
            <div class="step-panel_body">
                <div class="form-set">
                    <div class="form-input">
                        <label>UPI<span class="estrx"> *</span></label>
                        <div>
                            <input type="text" name="UPI" formControlName="UPI" class="flg" placeholder="" readonly />
                        </div>
                    </div>
                    <div class="form-input">
                        <label>Certificate Number<span class="estrx"> *</span></label>
                        <div>
                            <input type="text" name="certificateNumber" formControlName="certificateNumber" class="flg"
                                placeholder="" readonly />
                        </div>
                    </div>
                    <div>
                        <label for="siteStatusId">Site Status</label>
                        <select formControlName="siteStatusId">
                            <option value="" disabled>Select</option>
                            <option *ngFor="let st of siteStatuses" [value]="st.id">{{st.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-set">
                    <div>
                        <label for="reason">Reason</label>
                        <textarea formControlName="reason"></textarea>
                    </div>
                </div>
                <div class="form-set">
                    <div>
                        <label for="comments">Comments</label>
                        <textarea formControlName="comments"></textarea>
                    </div>
                </div>
                <div class="form-set">
                    <div>
                        <div class="form-input">
                            <app-input-file-upload (fileUpload)="getFileToSave($event)"></app-input-file-upload>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="step-panel_footer">
                <button class="kbk-btn kbk-btn-sec" type="button" (click)="cancel()">Cancel</button>
                <button class="kbk-btn kbk-btn-main" type="submit">Submit </button>
            </div>
        </form>
    </div>
</div>