<div class="form-input">
  <label>Supporting Document *</label>
  <div class="form-inputfield">
    <div class="form-uploadfile">
      <label class="form-uploadfile-btn">
        <input type="file" multiple (change)="getFileDetails($event)" id="document" name="document"
          style="display: none" /> Choose a file </label>
      <span class="form-uploadfile-text">{{fileData.fileName}}</span>
    </div>
  </div>
</div>
<!-- <div class="form-input">
  <label>Upload Document *</label>
  <div class="form-inputfield">
    <div class="form-uploaddocs">
      <label class="form-uploaddocs-btn">
        <input type="file" style="display: none" />
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <g data-name="Layer 2">
            <g data-name="attach">
              <rect width="24" height="24" opacity="0" />
              <path
                d="M9.29 21a6.23 6.23 0 0 1-4.43-1.88 6 6 0 0 1-.22-8.49L12 3.2A4.11 4.11 0 0 1 15 2a4.48 4.48 0 0 1 3.19 1.35 4.36 4.36 0 0 1 .15 6.13l-7.4 7.43a2.54 2.54 0 0 1-1.81.75 2.72 2.72 0 0 1-1.95-.82 2.68 2.68 0 0 1-.08-3.77l6.83-6.86a1 1 0 0 1 1.37 1.41l-6.83 6.86a.68.68 0 0 0 .08.95.78.78 0 0 0 .53.23.56.56 0 0 0 .4-.16l7.39-7.43a2.36 2.36 0 0 0-.15-3.31 2.38 2.38 0 0 0-3.27-.15L6.06 12a4 4 0 0 0 .22 5.67 4.22 4.22 0 0 0 3 1.29 3.67 3.67 0 0 0 2.61-1.06l7.39-7.43a1 1 0 1 1 1.42 1.41l-7.39 7.43A5.65 5.65 0 0 1 9.29 21z"
              />
            </g>
          </g>
        </svg>
        Choose a file
      </label>
    </div>
    <div class="form-uploaddocs-load">
      <span class="form-uploaddocs-text">Uploaded application letter.pdf</span>
      <span class="form-uploaddocs-loader"></span>
    </div>
  </div>
</div>

<div class="uploaded-content">
  <h3 class="mgn">Uploaded Documents</h3>
  <ul class="uploaded-list">
    <li class="uploaded-file">
      <img src="assets/ikons/colored/ikon-file-blue.svg" alt="" />
      <span>Document.pdf</span>
      <div class="xtions">
        <a class="kbk-link">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g data-name="Layer 2">
              <g data-name="edit">
                <rect width="24" height="24" opacity="0" />
                <path
                  d="M19.4 7.34L16.66 4.6A2 2 0 0 0 14 4.53l-9 9a2 2 0 0 0-.57 1.21L4 18.91a1 1 0 0 0 .29.8A1 1 0 0 0 5 20h.09l4.17-.38a2 2 0 0 0 1.21-.57l9-9a1.92 1.92 0 0 0-.07-2.71zM9.08 17.62l-3 .28.27-3L12 9.32l2.7 2.7zM16 10.68L13.32 8l1.95-2L18 8.73z"
                />
              </g>
            </g>
          </svg>
        </a>
        <a class="kbk-link">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g data-name="Layer 2">
              <g data-name="trash-2">
                <rect width="24" height="24" opacity="0" />
                <path
                  d="M21 6h-5V4.33A2.42 2.42 0 0 0 13.5 2h-3A2.42 2.42 0 0 0 8 4.33V6H3a1 1 0 0 0 0 2h1v11a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V8h1a1 1 0 0 0 0-2zM10 4.33c0-.16.21-.33.5-.33h3c.29 0 .5.17.5.33V6h-4zM18 19a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V8h12z"
                />
                <path
                  d="M9 17a1 1 0 0 0 1-1v-4a1 1 0 0 0-2 0v4a1 1 0 0 0 1 1z"
                />
                <path
                  d="M15 17a1 1 0 0 0 1-1v-4a1 1 0 0 0-2 0v4a1 1 0 0 0 1 1z"
                />
              </g>
            </g>
          </svg>
        </a>
      </div>
    </li>
    <li class="uploaded-file">
      <img src="assets/ikons/colored/ikon-file-blue.svg" alt="" />
      <span>Document.pdf</span>
      <div class="xtions">
        <a class="kbk-link">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g data-name="Layer 2">
              <g data-name="edit">
                <rect width="24" height="24" opacity="0" />
                <path
                  d="M19.4 7.34L16.66 4.6A2 2 0 0 0 14 4.53l-9 9a2 2 0 0 0-.57 1.21L4 18.91a1 1 0 0 0 .29.8A1 1 0 0 0 5 20h.09l4.17-.38a2 2 0 0 0 1.21-.57l9-9a1.92 1.92 0 0 0-.07-2.71zM9.08 17.62l-3 .28.27-3L12 9.32l2.7 2.7zM16 10.68L13.32 8l1.95-2L18 8.73z"
                />
              </g>
            </g>
          </svg>
        </a>
        <a class="kbk-link">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g data-name="Layer 2">
              <g data-name="trash-2">
                <rect width="24" height="24" opacity="0" />
                <path
                  d="M21 6h-5V4.33A2.42 2.42 0 0 0 13.5 2h-3A2.42 2.42 0 0 0 8 4.33V6H3a1 1 0 0 0 0 2h1v11a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V8h1a1 1 0 0 0 0-2zM10 4.33c0-.16.21-.33.5-.33h3c.29 0 .5.17.5.33V6h-4zM18 19a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V8h12z"
                />
                <path
                  d="M9 17a1 1 0 0 0 1-1v-4a1 1 0 0 0-2 0v4a1 1 0 0 0 1 1z"
                />
                <path
                  d="M15 17a1 1 0 0 0 1-1v-4a1 1 0 0 0-2 0v4a1 1 0 0 0 1 1z"
                />
              </g>
            </g>
          </svg>
        </a>
      </div>
    </li>
    <li class="uploaded-file">
      <img src="assets/ikons/colored/ikon-file-blue.svg" alt="" />
      <span>Document.pdf</span>
      <div class="xtions">
        <a class="kbk-link">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g data-name="Layer 2">
              <g data-name="edit">
                <rect width="24" height="24" opacity="0" />
                <path
                  d="M19.4 7.34L16.66 4.6A2 2 0 0 0 14 4.53l-9 9a2 2 0 0 0-.57 1.21L4 18.91a1 1 0 0 0 .29.8A1 1 0 0 0 5 20h.09l4.17-.38a2 2 0 0 0 1.21-.57l9-9a1.92 1.92 0 0 0-.07-2.71zM9.08 17.62l-3 .28.27-3L12 9.32l2.7 2.7zM16 10.68L13.32 8l1.95-2L18 8.73z"
                />
              </g>
            </g>
          </svg>
        </a>
        <a class="kbk-link">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g data-name="Layer 2">
              <g data-name="trash-2">
                <rect width="24" height="24" opacity="0" />
                <path
                  d="M21 6h-5V4.33A2.42 2.42 0 0 0 13.5 2h-3A2.42 2.42 0 0 0 8 4.33V6H3a1 1 0 0 0 0 2h1v11a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V8h1a1 1 0 0 0 0-2zM10 4.33c0-.16.21-.33.5-.33h3c.29 0 .5.17.5.33V6h-4zM18 19a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V8h12z"
                />
                <path
                  d="M9 17a1 1 0 0 0 1-1v-4a1 1 0 0 0-2 0v4a1 1 0 0 0 1 1z"
                />
                <path
                  d="M15 17a1 1 0 0 0 1-1v-4a1 1 0 0 0-2 0v4a1 1 0 0 0 1 1z"
                />
              </g>
            </g>
          </svg>
        </a>
      </div>
    </li>
  </ul>
</div> -->