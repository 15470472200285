import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { jwtDecode } from 'jwt-decode';
import { ApplicationService } from 'src/app/application/services/application.service';
import { SessionService } from 'src/app/authentication-services/session.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-development-status',
  templateUrl: './development-status.component.html',
  styleUrls: ['./development-status.component.scss']
})
export class DevelopmentStatusComponent {
  @Input() inputData: any;
  @Output() backToParent = new EventEmitter();
  associatedForm!: FormGroup;
  currentUser: any;
  lists: any[] = [];
  page = 1;
  pageSize = 10;
  startIndex = 0;
  endIndex = 9;
  totalRecords = 0;
  fullList: any[] = [];
  filteredList: any[] = [];
  collectionSize = 0;
  isNewUpi: boolean = false;
  siteStatuses: any[] = [];

  constructor(private fb: FormBuilder,
    public applicationService: ApplicationService,
    private sessionService: SessionService,) {
    this.currentUser = this.sessionService.getSession();
    this.currentUser.userId = (jwtDecode(this.currentUser.data.token.access_token) as any).UserId;


    this.applicationService.findAllWithPath(environment.applicationUrl + 'certificate/siteStatus')
      .subscribe(
        data => {
          this.siteStatuses = data
        }
      )
  }

  closeList() {
    this.isNewUpi = !this.isNewUpi;
  }


  closeForm() {
    this.isNewUpi = false;
  }

  ngOnDestroy() {
    this.applicationService.searchTerm = '';
  }

  ngOnInit(): void {
    console.log(this.inputData);
    this.lists = this.inputData?.applicationDetail?.developmentStatus
    this.associatedForm = this.fb.group({
      UPI: [this.inputData.applicationDetail.applications.projects.upi, Validators.required],
      applicationId: [this.inputData.applicationDetail.applications.id, Validators.required],
      userId: [this.currentUser.userId, Validators.required],
      certificateId: [this.inputData.applicationDetail.id],
      startingDate: [],
      siteStatusId: [],
    });
    // this.loadList();

  }

  loadList() {
    this.applicationService.findAllWithPath(environment.applicationUrl + 'certificate/certificate/allData/' + this.inputData.applicationDetail.id
    ).subscribe(
      dataItem => {
        this.lists = dataItem[0].developmentStatus;

      }
    )
  }


  getPremiumData() {
    this.filterAndPaginate();
  }

  filterAndPaginate() {
    const filtered = this.fullList.filter(item => this.applyFilter(item));
    this.filteredList = filtered;
    this.totalRecords = filtered.length;
    this.startIndex = (this.page - 1) * this.pageSize + 1;
    this.endIndex = (this.page - 1) * this.pageSize + this.pageSize;
    if (this.endIndex > this.totalRecords) {
      this.endIndex = this.totalRecords;
    }
    this.lists = filtered.slice(this.startIndex - 1, this.endIndex);
  }
  onSearchTermChange(term: string): void {
    this.applicationService.searchTerm = term;
    this.filterAndPaginate();
  }

  applyFilter(item: any): boolean {
    if (!this.applicationService.searchTerm) return true;
    const term = this.applicationService.searchTerm.toLowerCase();
    return Object.values(item).some(val =>
      String(val).toLowerCase().includes(term)
    );
  }

  cancel() {
    // this.backToParent.emit();
    this.isNewUpi = !this.isNewUpi;
  }



  onSubmit() {
    this.applicationService.saveAssetWithPath(this.associatedForm.value, environment.applicationUrl + 'certificate/developmentStatus')
      .subscribe(
        data => {
          this.cancel();
          this.loadList();
        }, error => { })
  }
}
