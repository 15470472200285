
<div class="stepper-content">
    <div class="stepper-header">
        <div class="upper-counter"> Step <span>3</span> / <span>4</span> </div>
    </div>
    <div class="stepper-body">
        <div>
            <!-- Step content goes here -->
            <div class="step-panel">
                <div class="step-panel_header">
                    <h2>Project Details</h2>
                    <div class="form-incheckbox">
                        <label class="checkbox">
                            <input style="display: none;" type="checkbox" id="check" [checked]="isAssociated"
                                (click)="checkAssociated(associatedUpiContent)" data-bs-toggle="modal" type="button"
                                data-bs-target="#showModal" />
                            <span class="checkbox_box"></span>
                            <span class="checkbox_txt">Check to add associated UPI</span>
                        </label>
                    </div>
                </div>
                <form [formGroup]="pageForm" autocomplete="off" *ngIf="currentUser.data.user.userType.code === 'LO'">
                    <div class="step-panel_body">
                        <div class="form-set">
                            <div class="form-incheckbox">
                                <label class="checkbox">
                                    <input type="checkbox" id="check" [checked]="doYouWantToDoItYouself"
                                        (change)="changeForm()" />
                                    <span class="checkbox_box"></span>
                                    <span class="checkbox_txt">Do you want to do it by yourself</span>
                                </label>
                            </div>
                            <div class="form-incheckbox">
                                <label class="checkbox">
                                    <input type="checkbox" id="check" [checked]="doYouWantToAssignToEngineer"
                                        (change)="changeAssignForm()" />
                                    <span class="checkbox_box"></span>
                                    <span class="checkbox_txt">Assign this application to the Engineer</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </form>
                <form [formGroup]="userForm" (ngSubmit)="onSubmit()" autocomplete="off" *ngIf="doYouWantToDoItYouself">
                    <div class="step-panel_body">
                        <div class="form-set">
                            <div class="form-input">
                                <label>Permit Type <span class="estrx">*</span></label>
                                <div>
                                    <select [class.disabled-select]="userForm.value.applicationId" name="permitTypeId" id="permitTypeId"
                                    formControlName="permitTypeId"
                                      (change)="checkTemporalPermit()"
                                       required>
                                        <option *ngFor="let op of permitTypes" [value]="op.id">{{op.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Plot Size <span class="estrx">*</span></label>
                                <div>
                                    <input type="text" id="plotSize"
                                    name="plotSize"
                                        formControlName="plotSize" readonly>
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Building Type <span class="estrx">*</span></label>
                                <div>
                                    <select name="buildTypeId" id="buildTypeId" formControlName="buildTypeId"
                                        (change)="getBuildTypeObject($event)" required>
                                        <option *ngFor="let op of buildingTypes" [value]="op.id">{{op.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Project Category <span class="estrx">*</span></label>
                                <div>
                                    <!--  -->
                                    <select [class.disabled-select]="isSelectDisabled" name="categoryTypeId"
                                        id="categoryTypeId" formControlName="categoryTypeId"
                                        [disabled]="isSelectDisabled" required>
                                        <option *ngFor="let op of categoryTypes" [value]="op.id">{{op.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Number of Floor <span class="estrx">*</span></label>
                                <div>
                                    <input type="number" id="numberOfFloor" name="numberOfFloor"
                                    noNegative
                                        formControlName="numberOfFloor" (keyup)="calculateNumberOfFlow()" required>
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Built-up Area (In Square Meters) <span class="estrx">*</span></label>
                                <div>
                                    <!--  -->
                                    <input type="number" id="buildUpArea" name="buildUpArea"

                                        formControlName="buildUpArea" (focusout)="focusOutFunction()"
                                        (keyup)="calculateNumberOfFlow()"
                                        noNegative required [disabled]="userForm.value.buildTypeId.length < 0 || userForm.value.buildTypeId.length == 0">
                                    <div class="text-danger" *ngIf="userForm.hasError('greaterThanPlotSize')"> Build Up
                                        Area cannot be greater than Plot Size. </div>
                                </div>
                            </div>

                            <div class="form-input">
                                <label>Gross Floor Area <span class="estrx">*</span></label>
                                <div>
                                    <input type="number" id="grossFloorArea" name="grossFloorArea"
                                        formControlName="grossFloorArea"
                                        noNegative readonly required>
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Number of parking spaces <span class="estrx">*</span></label>
                                <div>
                                    <input type="number" id="numberOfParkingSpace" name="numberOfParkingSpace"
                                        formControlName="numberOfParkingSpace"
                                        noNegative required>
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Estimated price of dwelling unit in RWF <span class="estrx">*</span></label>
                                <div>
                                    <input type="text" appCommaSeparator id="priceOfDwellingUnitRwf"
                                        name="priceOfDwellingUnitRwf" formControlName="priceOfDwellingUnitRwf" required
                                        autocomplete="off"

                                        >
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Description of operations <span class="estrx">*</span></label>
                                <div>
                                    <input type="text" id="DescriptionOfOperation" name="DescriptionOfOperation"
                                        formControlName="DescriptionOfOperation" required>
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Percentage Space Use <span class="estrx">*</span></label>
                                <div>
                                    <input type="number" id="percentageSpaceUse" name="percentageSpaceUse"
                                        formControlName="percentageSpaceUse" readonly required>
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Number of Dwelling Unit <span class="estrx">*</span></label>
                                <div>
                                    <input type="text" id="numberOfDwellingUnits" name="numberOfDwellingUnits"
                                        formControlName="numberOfDwellingUnits" autocomplete="off">
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Estimated Water Consumption (m3) <span class="estrx">*</span></label>
                                <div>
                                    <input type="number" id="waterConsumption" name="waterConsumption"
                                        formControlName="waterConsumption"
                                        noNegative required autocomplete="off">
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Estimated Electricity Consumption(watts) <span class="estrx">*</span></label>
                                <div>
                                    <input type="number" id="electricityConsumption"
                                     name="electricityConsumption"
                                        formControlName="electricityConsumption"
                                        noNegative required autocomplete="off">
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Distance to the nearest Land Line/ optic fiber cable(m) <span class="estrx">*</span></label>
                                <div>
                                    <input type="number" id="DistanceToTheNearestLandIn"
                                        name="DistanceToTheNearestLandIn"
                                        formControlName="DistanceToTheNearestLandIn"
                                        noNegative
                                        required autocomplete="off">
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Estimated project cost in USD <span class="estrx">*</span></label>
                                <div>
                                    <input type="text" appCommaSeparator id="ProjectCostInUSD" name="ProjectCostInUSD"
                                        formControlName="ProjectCostInUSD" required autocomplete="off">
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Estimated project cost in RWF <span class="estrx">*</span></label>
                                <div>
                                    <input type="text" appCommaSeparator id="ProjectCostInRwf" name="ProjectCostInRwf"
                                        formControlName="ProjectCostInRwf" required autocomplete="off">
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Capacity Information: Number of people / seats <span class="estrx">*</span></label>
                                <div>
                                    <input type="number" id="capacityInformation" name="capacityInformation"
                                        formControlName="capacityInformation"
                                        noNegative (keyup)="calculateNumberOfPeople()"
                                        required autocomplete="off">
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Technology Survey (Optional) <span class="estrx">*</span></label>
                                <div>
                                    <select name="technologySurveyId" id="technologySurveyId"
                                        formControlName="technologySurveyId">
                                        <option *ngFor="let op of technologySurveys" [value]="op.id">{{op.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="step-panel_body">
                            <div class="form-incheckbox" *ngIf="(userForm.value.categoryCode === 'CAT1' ||
                    userForm.value.categoryCode === 'CAT2')">
                                <label class="checkbox">
                                    <input type="checkbox" id="check" [checked]="formAccess.doYouWantToShareToEngineer"
                                        (click)="allowToAssign()" />
                                    <span class="checkbox_box"></span>
                                    <span class="checkbox_txt">Select this option if you want to assign this project to
                                        an engineer/architect or continue on your own.</span>
                                </label>
                            </div>
                        </div> -->
                    </div>
                    <div class="step-panel_footer" *ngIf="
                (((userForm.value.categoryCode === 'CAT1' ||
                userForm.value.categoryCode === 'CAT2') &&
                (!formAccess.doYouWantToShareToEngineer))
                && (!this.pageForm.value.doYouWantToAssignToEngineer)) && currentUser.data.user.role.code === 'APP'">
                        <button (click)="onPrev()">Previous</button>
                        <button type="submit">Next</button>
                    </div>

                    <div class="step-panel_footer" *ngIf="
                (userForm.value.categoryCode === 'CAT1' ||
                userForm.value.categoryCode === 'CAT2' ||
                userForm.value.categoryCode === 'CAT3'||
                userForm.value.categoryCode === 'CAT4' ||
                userForm.value.categoryCode === 'CAT5') && currentUser.data.user.role.code === 'ENG'">
                        <button (click)="onPrev()">Previous</button>
                        <button type="submit">Next</button>
                    </div>
                </form>
                <!-- EIA Checker form -->
                <div class="alert alert-danger" role="alert"
                    *ngIf="!formAccess.isIAEverified &&
                    formAccess.isRdbIAEchecked
                    && (userForm.value.categoryCode === 'CAT5' ||
                    userForm.value.categoryCode === 'CAT4')"> Data
                    not found. Please submit your request to RDB for an Environmental Impact Access certificate! </div>
                <app-eia-form-checker *ngIf="
                (userForm.value.categoryCode === 'CAT5' ||
                userForm.value.categoryCode === 'CAT4') && this.applicationDetails.upi"
                    [inputData]="{upi: this.applicationDetails.upi}"
                    (backToParent)="comeAndAllowToAssign($event)"></app-eia-form-checker>
                <!-- <div class="form-incheckbox" *ngIf="
                    (userForm.value.categoryCode === 'CAT5' ||
                    userForm.value.categoryCode === 'CAT3' ||
                    userForm.value.categoryCode === 'CAT4')">
                    <label class="checkbox">
                        <span class="checkbox_txt">Your project seems to be in category {{userForm.value.categoryCode}}.
                            To make it simpler for you, you just need to decide whether you need an engineer or an
                            architect.</span>
                    </label>
                </div> -->
                <!-- <app-assign-to-engineer-form *ngIf="
                (((
                    userForm.value.categoryCode === 'CAT1' ||
                    userForm.value.categoryCode === 'CAT2' ||
                    userForm.value.categoryCode === 'CAT3') && formAccess.doYouWantToShareToEngineer)
                || ((userForm.value.categoryCode === 'CAT4' || userForm.value.categoryCode === 'CAT5') && formAccess.isIAEverified)
                    )" [inputData]="userForm.value.projectId"></app-assign-to-engineer-form> -->
                <!-- <app-assign-to-engineer-form *ngIf="doYouWantToAssignToEngineer ||
                    (userForm.value.categoryCode === 'CAT5' && formAccess.isIAEverified)
                        " [inputData]="userForm.value.projectId"></app-assign-to-engineer-form> -->
                <!-- <app-assign-to-engineer-form *ngIf="doYouWantToAssignToEngineer ||
                userForm.value.categoryCode === 'CAT3' ||
                        ((userForm.value.categoryCode === 'CAT4' ||
                        userForm.value.categoryCode === 'CAT5') && formAccess.isIAEverified
                        && formAccess.isRdbIAEchecked)
                            " [inputData]="userForm.value.projectId"
                    [inputData]="{allowToSubmit: formAccess.isIAEverified}"></app-assign-to-engineer-form> -->
                <!-- <app-assign-to-engineer-form *ngIf="
                    doYouWantToAssignToEngineer ||
                    userForm.value.categoryCode === 'CAT3' ||
                        ((userForm.value.categoryCode === 'CAT4' ||
                        userForm.value.categoryCode === 'CAT5')
                        && formAccess.isRdbIAEchecked)
                            " [inputData]="{
                        projectId: userForm.value.projectId,
                        allowToSubmit: formAccess.isIAEverified}"></app-assign-to-engineer-form> -->


                        <app-assign-to-engineer-form *ngIf="
                    doYouWantToAssignToEngineer ||
                    userForm.value.categoryCode === 'CAT3' ||
                        ((userForm.value.categoryCode === 'CAT4' ||
                        userForm.value.categoryCode === 'CAT5')
                        && formAccess.isRdbIAEchecked && currentUser.data.user.role.code !== 'ENG')
                            " [inputData]="{
                        projectId: userForm.value.projectId,
                        allowToSubmit: formAccess.isIAEverified}"></app-assign-to-engineer-form>
            </div>
        </div>
    </div>
</div>




<ng-template #associatedUpiContent role="document" let-modal>
    <div class="modol-header">
        <h2 class="exampleModalLabel">Associated UPI</h2>
        <!-- <span class="caption">Fill required input to create new user</span> -->
        <button type="button" class="kbk-btn kbk-btn-sec kbk-btn-close" data-bs-dismiss="modal" aria-label="Close"
            id="close-modal" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modo-contain" *ngIf="outputData">
        <app-associated-upi-application [inputData]="outputData"
            (backToParent)="closeAssociatedPopup($event)"></app-associated-upi-application>
    </div>
</ng-template>
