// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  authUrl: 'https://api-gatway.kubaka.gov.rw/3001/',
  applicationUrl: 'https://api-gatway.kubaka.gov.rw/3000/',
  documentUrl: 'https://api-gatway.kubaka.gov.rw/3005/',
  integrationUrl: 'https://api-gatway.kubaka.gov.rw/3007/',
  chatUrl: 'https://api-gatway.kubaka.gov.rw/3004/',
  nidaUrl: 'https://api-gatway.kubaka.gov.rw/3007/integration/nida?documentNumber=',
  // // nidaUrl: 'http://localhost:3007/integration/nida?documentNumber=',
  // // nidaUrl: 'https://apis.kubaka.gov.rw/apis/nidInfo/?documentNumber=',
  // // nidaUrl: 'https://api-gatway.kubaka.gov.rw/3007/integration?documentNumber=',
  // iremboPayKey: 'pk_live_006583a232684ae2979cb7ba91ea6e5e',
  // // eia: 'https://apis.kubaka.gov.rw/apis/verify/eia',
  // eia: 'http://testing.kubaka.gov.rw/testing-api/verify/eia'





  // authUrl: 'http://197.243.12.45:3001/',
  // applicationUrl: 'http://197.243.12.45:3000/',
  // documentUrl: 'http://197.243.12.45:3005/',
  // integrationUrl: 'http://197.243.12.45:3007/',

  // chatUrl: 'http://197.243.12.45:3004/',
  // nidaUrl: 'http://197.243.12.45:3007/integration/nida?documentNumber=',
  iremboPayKey: 'pk_live_006583a232684ae2979cb7ba91ea6e5e',
  // iremboPayKey: 'pk_live_9893b7756019459c9c2801b774038d7b',
  eia: 'http://testing.kubaka.gov.rw/testing-api/verify/eia',
  landAPI: 'https://api.kubaka.gov.rw/land?upi=',
  // eia: 'https://testing.kubaka.gov.rw/testing-api/verify/eia'

  API_KEY: '13C119551E1FA51BF6C293CB17782'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
