import { Component } from '@angular/core';

@Component({
  selector: 'app-application-response-from-search',
  templateUrl: './application-response-from-search.component.html',
  styleUrls: ['./application-response-from-search.component.scss']
})
export class ApplicationResponseFromSearchComponent {

}
