import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { jwtDecode } from 'jwt-decode';
import { ApplicationService } from 'src/app/application/services/application.service';
import { SessionService } from 'src/app/authentication-services/session.service';
import { APIURLPATH } from 'src/app/shared/services/url-path';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-generic-approval',
  templateUrl: './generic-approval.component.html',
  styleUrls: ['./generic-approval.component.scss']
})
export class GenericApprovalComponent {
  @Input() inputData: any;
  userForm!: UntypedFormGroup;
  approvalStatuses: any[] = [];
  currentUser: any = {};
  submitted: any = false;
  @Output() backToParent = new EventEmitter();

  constructor(
    private applicationService: ApplicationService,
    private formBuilder: UntypedFormBuilder,
    private sessionService: SessionService
  ) {
    if (this.sessionService.getSession() && this.sessionService.getSession().data && this.sessionService.getSession().data.user) {
      this.currentUser = this.sessionService.getSession();
      this.currentUser.userId = (jwtDecode(this.currentUser.data.token.access_token) as any).UserId;
    }


    this.applicationService.findAllWithPath(environment.applicationUrl + APIURLPATH.APPROVAL_STATUS)
      .subscribe(
        data => {
          this.approvalStatuses = data;
        }
      )
  }


  ngOnInit(): void {
    console.log(this.inputData);
    this.userForm = this.formBuilder.group({
      id: [""],
      comment: ['', [Validators.required]],
      applicationApprovalId: ['', Validators.required],


      // approve document
      applicationId: [this.inputData.isDocumentApproval ? this.inputData.applicationId : ''],
      requiredDocumentId: [this.inputData.isDocumentApproval ? this.inputData.requiredDocumentId : '']
    });
  }


  onSubmit() {
    this.submitted = true;
    if (this.findApprovaStatus()) {
      let dataToSubmit = {
        "documentId": this.inputData.id,
        "userId": this.currentUser.userId,
        "applicationId": this.inputData.applicationId,
        "requiredDocumentId": this.inputData.requiredDocumentId,
        "approvalStatusId": this.findApprovaStatus()
      }
      if (this.inputData.isDocumentApproval) {
        this.applicationService.saveAssetWithPath(dataToSubmit, environment.applicationUrl + 'approval/approvalDocument')
          .subscribe(
            data => {
              this.applicationService.updateAssetWithoutParams(dataToSubmit, environment.documentUrl + 'DocMgt/' + dataToSubmit.documentId + '/status')
                .subscribe(
                  data => {
                    this.backToParent.emit(true);
                    this.submitted = false;
                  }
                )
            }, error => {
              this.submitted = false;
            }
          )
      }
    }
  }


  findApprovaStatus() {
    let value = this.approvalStatuses.find((x: any) => x.code === 'APPD').id;
    return value;
  }
}
