<div class="stepper-content">
    <div class="stepper-header">
        <div class="upper-counter"> Personal Info <!-- <span>3</span> / <span>4</span>  -->
        </div>
    </div>
    <div class="stepper-body">
        <div>
            <!-- Step content goes here -->
            <div class="step-panel">
                <div class="step-panel_header">
                    <h2>Edit profile</h2>
                </div>
                <form [formGroup]="userForm" (ngSubmit)="onSubmit()" autocomplete="off">
                    <div class="step-panel_body">
                        <div class="form-set">
                            <div class="form-input">
                                <label>First name<span>*</span></label>
                                <div>
                                    <input type="text" id="firstName" name="firstName" formControlName="firstName"
                                        readonly>
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Last name<span>*</span></label>
                                <div>
                                    <input type="text" id="lastName" name="lastName" formControlName="lastName"
                                        readonly>
                                </div>
                            </div>
                        </div>
                        <div class="form-set">
                            <div class="form-input">
                                <label>Email</label>
                                <div>
                                    <input type="email" id="email" name="email" formControlName="email" required>
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Phone number</label>
                                <div>
                                    <input type="text" id="phoneNumber" name="phoneNumber" formControlName="phoneNumber"
                                        required>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="step-panel_footer">
                        <button>Cancel</button>
                        <button *ngIf="!submitted" type="submit">Update</button>
                        <button *ngIf="submitted" type="button">Updating...</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>