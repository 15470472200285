<div class="step-panel">
    <form [formGroup]="userForm" (ngSubmit)="submit()">
        <div class="step-panel_header">
            <h2>Due to its category, this project must be assigned to an engineer/architect. Please select and search
            </h2>
        </div>

        <div class="step-panel_body">
            <div class="kbk-x">
                <div class="form-set">
                    <div class="form-input">
                        <label>User type</label>
                        <div>
                            <select name="userCategoryId" id="userCategoryId" formControlName="userCategoryId"
                                (change)="changeDetectionValue()" required>
                                <option *ngFor="let op of userTypes" [value]="op.id">{{op.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-input">
                        <label>Engineer/architect license number</label>
                        <div>
                            <input type="text" id="licenseNumber" name="licenseNumber" formControlName="licenseNumber"
                                (focusout)="checkEngineer()" required>
                        </div>
                    </div>
                    <div class="form-input">
                        <label>Names</label>
                        <div>
                            <input type="text" id="names" name="names" formControlName="names" readonly required>
                        </div>
                    </div>
                    <div class="form-input">
                        <label>Days needed for submission</label>
                        <div>
                            <input type="text" id="timeLineDays" name="timeLineDays" formControlName="timeLineDays" required>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="alert alert-danger" role="alert" *ngIf="userForm.value.message"> {{userForm.value.message}} </div>
        <div class="step-panel_footer">
            <!-- <button (click)="onPrev()">Previous</button> -->
            <button type="button" *ngIf="submitted"> Wait... </button>
            <button type="submit" *ngIf="!submitted"> Assign </button>
        </div>
    </form>
</div>
