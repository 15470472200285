<div class="stepper-content">
    <div class="stepper-header">
        <div class="upper-counter"> Step <span>3</span> / <span>4</span> </div>
    </div>
    <div class="stepper-body">
        <div>
            <div class="step-panel">
                <div class="step-panel_header">
                    <h2>{{upiInfo?.isFromOldSystem ? 'Your project already found in old system complete the missing details and proceed' : 'Development Details'}}</h2>
                    <div class="form-incheckbox">
                        <label class="checkbox">
                            <input style="display: none;" type="checkbox" id="check" [checked]="isAssociated"
                                (click)="checkAssociated(associatedUpiContent)" data-bs-toggle="modal" type="button"
                                data-bs-target="#showModal" />
                            <span class="checkbox_box"></span>
                            <span class="checkbox_txt">Check to add associated UPI</span>
                        </label>
                    </div>
                </div>
                <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
                    <div class="step-panel_body">
                        <div class="form-set">
                            <div class="form-input">
                                <label>Project Name</label>
                                <div>
                                    <textarea name="projectName" formControlName="projectName" id="projectName"
                                        cols="30" rows="5"></textarea>
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Project Description</label>
                                <div>
                                    <textarea name="projectDescription" formControlName="projectDescription"
                                        id="projectDescription" cols="30" rows="5"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="form-set">
                            <div class="form-input">
                                <label>Plot Size (In Square Meters) <span class="estrx">*</span></label>
                                <div>
                                    <input type="number" id="plotSize" name="plotSize" formControlName="plotSize"
                                        readonly required>
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Built-up Area (In Square Meters) <span class="estrx">*</span>
                                </label>
                                <div>
                                    <input type="number" id="buildUpArea" name="buildUpArea"
                                        formControlName="buildUpArea" (focusout)="focusOutFunction()"
                                        (keyup)="calculateGrossFlowArea()" noNegative 
                                        required>
                                    <div class="text-danger" *ngIf="userForm.hasError('greaterThanPlotSize')"> Build Up
                                        Area cannot be greater than Plot Size. </div>
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Number of Floor <span class="estrx">*</span></label>
                                <div>
                                    <input type="number" id="numberOfFloor"
                                    (keyup)="calculateGrossFlowArea()" name="numberOfFloor"
                                        formControlName="numberOfFloor" required>
                                </div>
                            </div>
                            
                            <div class="form-input">
                                <label>Gross Floor Area</label>
                                <div>
                                    <input type="number" id="grossFloorArea" name="grossFloorArea"
                                    noNegative
                                        formControlName="grossFloorArea"
                                        readonly required>
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Number of parking spaces</label>
                                <div>
                                    <input type="number" id="numberOfParkingSpace" name="numberOfParkingSpace"
                                        formControlName="numberOfParkingSpace"
                                        noNegative
                                         required>
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Estimated price of dwelling unit in RWF</label>
                                <div>
                                    <input type="text" appCommaSeparator id="priceOfDwellingUnitRwf" name="priceOfDwellingUnitRwf"
                                        formControlName="priceOfDwellingUnitRwf" required>
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Number of Dwelling Unit</label>
                                <div>
                                    <input type="text" id="numberOfDwellingUnits" name="numberOfDwellingUnits"
                                        formControlName="numberOfDwellingUnits" required>
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Description of operations</label>
                                <div>
                                    <input type="text" id="DescriptionOfOperation" name="DescriptionOfOperation"
                                        formControlName="DescriptionOfOperation" required>
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Percentage Space Use</label>
                                <div>
                                    <input type="number" id="percentageSpaceUse" name="percentageSpaceUse"
                                        formControlName="percentageSpaceUse"
                                         readonly required>
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Estimated Water Consumption (m3)</label>
                                <div>
                                    <input type="number" id="waterConsumption" name="waterConsumption"
                                        formControlName="waterConsumption"
                                        noNegative required>
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Estimated Electricity Consumption</label>
                                <div>
                                    <input type="number" id="electricityConsumption" name="electricityConsumption"
                                        formControlName="electricityConsumption"
                                        noNegative required>
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Distance to the nearest Land Line/ optic fiber cable(m)</label>
                                <div>
                                    <input type="number" id="DistanceToTheNearestLandIn"
                                        name="DistanceToTheNearestLandIn" 
                                        formControlName="DistanceToTheNearestLandIn"
                                        noNegative
                                        required>
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Estimated project cost in USD</label>
                                <div>
                                    <input type="text" appCommaSeparator id="ProjectCostInUSD" name="ProjectCostInUSD"
                                        formControlName="ProjectCostInUSD" required>
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Estimated project cost in RWF</label>
                                <div>
                                    <input type="text" appCommaSeparator id="ProjectCostInRwf" name="ProjectCostInRwf"
                                        formControlName="ProjectCostInRwf" required>
                                </div>
                            </div>
                            <!-- <div class="form-input">
                                <label>Technology Survey</label>
                                <div>
                                    <select name="technologySurveyId" id="technologySurveyId"
                                        formControlName="technologySurveyId" required>
                                        <option *ngFor="let op of technologySurveys" [value]="op.id">{{op.name}}
                                        </option>
                                    </select>
                                </div>
                            </div> -->
                            <div class="form-input">
                                <label>Capacity Information: Number of people / seats</label>
                                <div>
                                    <input type="number" id="capacityInformation" name="capacityInformation"
                                        formControlName="capacityInformation"
                                        noNegative required>
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Selected Category Use</label>
                                <div>
                                    <input type="text" id="selectedCategoryUse" name="selectedCategoryUse"
                                        formControlName="selectedCategoryUse" readonly required>
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Selected Use</label>
                                <div>
                                    <input type="text" id="selectedUse" name="selectedUse"
                                        formControlName="selectedUse" readonly
                                         required>
                                </div>
                            </div>
                        </div>
                        <div class="form-set">
                            <div class="form-incheckbox">
                                <label class="checkbox">
                                    <input type="checkbox" id="check" formControlName="isFromOldSystem"
                                    [disabled]="isReadOnly" />
                                    <span class="checkbox_box"></span>
                                    <span class="checkbox_txt">Is application come from old system</span>
                                </label>
                            </div>
                            <div class="form-incheckbox">
                                <label class="checkbox">
                                    <input type="checkbox" id="check" name="isUnderMortgage"
                                        formControlName="isUnderMortgage" 
                                        [disabled]="isReadOnly"/>
                                    <span class="checkbox_box"></span>
                                    <span class="checkbox_txt">Is Under Mortgage</span>
                                </label>
                            </div>
                            <div class="form-incheckbox">
                                <label class="checkbox">
                                    <input type="checkbox" id="check" name="isUnderRestriction"
                                        formControlName="isUnderRestriction"
                                        [disabled]="isReadOnly" />
                                    <span class="checkbox_box"></span>
                                    <span class="checkbox_txt">Is Under Restriction</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="step-panel_footer">
                        <button (click)="cancel()">Cancel</button>
                        <button type="submit" [disabled]="userForm.invalid">Update</button>
                        <button *ngIf="applicationDetail.id" type="button" (click)="next()">Next</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>




<ng-template #associatedUpiContent role="document" let-modal>
    <div class="modol-header">
        <h2 class="exampleModalLabel">Associated UPI</h2>
        <!-- <span class="caption">Fill required input to create new user</span> -->
        <button type="button" class="kbk-btn kbk-btn-sec kbk-btn-close" data-bs-dismiss="modal" aria-label="Close"
            id="close-modal" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modo-contain" *ngIf="outputData">
        <app-associated-upi-application [inputData]="outputData"
            (backToParent)="closeAssociatedPopup($event)"></app-associated-upi-application>
    </div>
</ng-template>