<div (mousemove)="onUserAction()" (click)="onUserAction()" (keypress)="onUserAction()">
  <router-outlet></router-outlet> 
  {{ upiFinder }} 
  <div class="chat--container">
    <app-chat-bot *ngIf="showChat"></app-chat-bot>
    <button class="chat--btn" (click)="toggleChat()">
      <!-- Display Open or Close Icon Based on toggleIcon -->
      <ng-container *ngIf="toggleIcon === 'open'">
        <svg class="chatmsg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.37 20.5">
          <title>ikon-chat</title>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <g id="ikon-chat">
                <polygon class="cls-1" points="21.38 10 18.33 10 18.38 13 14.88 10 8.38 10 8.38 1 21.38 1 21.38 10" />
                <line class="cls-2" x1="11.87" y1="6" x2="11.87" y2="6" />
                <line class="cls-2" x1="14.87" y1="6" x2="14.87" y2="6" />
                <line class="cls-2" x1="17.87" y1="6" x2="17.87" y2="6" />
                <polyline class="cls-3"
                  points="4.74 9 1 9 1 16.88 3.67 16.88 3.63 19.5 6.69 16.88 12.38 16.88 12.38 13.94" />
              </g>
            </g>
          </g>
        </svg>
      </ng-container>
      <ng-container *ngIf="toggleIcon === 'close'">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <defs>
            <style>
              .cls-1 {
                fill: #0099e8;
              }

              .cls-2 {
                fill: none;
              }
            </style>
          </defs>
          <title>ikon-close</title>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <g id="ikon-close">
                <path class="cls-1"
                  d="M6.23,4.81A1,1,0,1,0,4.81,6.23L10.59,12,4.81,17.77a1,1,0,1,0,1.42,1.42L12,13.41l5.77,5.78a1,1,0,0,0,1.42-1.42L13.41,12l5.78-5.77a1,1,0,1,0-1.42-1.42L12,10.59Z" />
                <rect class="cls-2" width="24" height="24" />
              </g>
            </g>
          </g>
        </svg>
      </ng-container>
    </button>
  </div>
</div>
<!-- <div class="chat-icon-container">
    <app-chat-bot *ngIf="showChat"></app-chat-bot>
    <button class="chat-icon-btn" (click)="toggleChat()">
        
        <ng-container *ngIf="toggleIcon === 'open'">
            <svg width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="12" fill="#007BFF" />
                <path
                    d="M7 8H17C18.1 8 19 8.9 19 10V14C19 15.1 18.1 16 17 16H12L8 19V16H7C5.9 16 5 15.1 5 14V10C5 8.9 5.9 8 7 8Z"
                    fill="white" />
            </svg>
        </ng-container>
        <ng-container *ngIf="toggleIcon === 'close'">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="60" height="60">
                <circle cx="12" cy="12" r="12" fill="#007BFF" /> 
                <path d="M8 8L16 16M16 8L8 16" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
        </ng-container>
    </button>
</div> -->