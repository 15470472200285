import { Injectable } from '@angular/core';
import { jwtDecode } from "jwt-decode";
import { AppConfig } from 'src/app/app.config';


@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(
    private config: AppConfig
  ) { }


  setToken(token: string) {
    localStorage.setItem(this.config.JWT_Token, JSON.stringify(token));
  }

  public getToken(): string | null {
    return localStorage.getItem(this.config.JWT_Token) ? JSON.parse(localStorage.getItem(this.config.JWT_Token) as any) : null;
  }

  getCurrentUser() {
    if (localStorage.getItem(this.config.JWT_Token)) {
      const currentUser = jwtDecode(localStorage.getItem(this.config.JWT_Token) as any) as any;
      if (currentUser != null) {
        return currentUser;
      } else {
        return 'Guest';
      }
    }
  }
}
