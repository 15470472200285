<div class="login-container">
    <div class="login-content">
      <div class="illust">
          <img class="logo" src="assets/ikons/logo/NavLogo.svg" alt="">
          <img class="ill" src="assets/imgs/SVG/illustration.svg" alt="">
      </div>
      <div class="dtials">
        <form class="form">
          <div class="form-header">
            <h2 class="display-md">Verification</h2>
            <p>Please, Enter verification code send to your email fb*****nn@gmail.com</p>
          </div>
          <div class="form-body">
            <div class="form-input">
              <label>Verification Code</label>
              <div class="verform">
                <input class="flg" maxlength="1" type="text" />
                <input class="flg" maxlength="1" type="text" />
                <input class="flg" maxlength="1" type="text" />
                <input class="flg" maxlength="1" type="text" />
                <input class="flg" maxlength="1" type="text" />
                <input class="flg" maxlength="1" type="text" />
              </div>
            </div>
          </div>
          <div class="form-footer">
            <button class="kbk-btn kbk-btn-lg kbk-btn-main" type="submit">Send</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  
