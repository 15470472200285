<div *ngIf="!isNewInspection">
    <div class="app-dash">
        <div class="app-main">
            <div class="app-lists">
                <div class="app-tblist">
                    <div class="app-tblist_title">
                        <span class="hder" aria-label="header tittle">Inspections</span>
                        <div class="btns">
                            <div class="form-input clear-m w-aut">
                                <div class="form-input_search">
                                    <input type="text" name="searchTerm" [(ngModel)]="applicationService.searchTerm"
                                        (ngModelChange)="onSearchTermChange($event)"
                                        placeholder="Search for something...">
                                    <button type="button" class="btn">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <g data-name="Layer 2">
                                                <g data-name="search">
                                                    <rect width="24" height="24" opacity="0" />
                                                    <path
                                                        d="M20.71 19.29l-3.4-3.39A7.92 7.92 0 0 0 19 11a8 8 0 1 0-8 8 7.92 7.92 0 0 0 4.9-1.69l3.39 3.4a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42zM5 11a6 6 0 1 1 6 6 6 6 0 0 1-6-6z" />
                                                </g>
                                            </g>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <a class="kbk-btn kbk-btn-sec brtn-edth" data-bs-toggle="modal" id="create-btn"
                                data-bs-target="#showModal" (click)="closeList()">Close</a>
                            <a class="kbk-btn kbk-btn-main brtn-edth" data-bs-toggle="modal" id="create-btn"
                                data-bs-target="#showModal" (click)="newInspection('')">New</a>
                        </div>
                    </div>
                    <ul class="tblist" *ngIf="lists.length > 0">
                        <li class="tblist-item" *ngFor="let li of lists">
                            <div class="tblist-item_icon">
                                <img src="assets/ikons/colored/ikon-file-blue.svg" alt="" />
                            </div>
                            <div class="tblist-item_dt">
                                <span>
                                    <span class="ttl">Fire Extinguishers In Residence</span>
                                    {{li.fireExtinguishersInResidence=== 'true' ? 'Yes' : 'No'}}</span>
                                <span>
                                    <span class="ttl">Fire Blanket In Kitchen</span> {{li.fireBlanketInKitchen=== 'true'
                                    ? 'Yes' : 'No'}}</span>
                            </div>
                            <div class="tblist-item_dt txt-l">
                                <span>
                                    <span class="ttl">TwoExits Per Residence</span> {{li.twoExitsPerResidence=== 'true'
                                    ? 'Yes' : 'No'}} </span>
                                <span>
                                    <span class="ttl">Emergency Exit</span> {{li.emergencyExit=== 'true' ? 'Yes' :
                                    'No'}}</span>
                            </div>
                            <div class="tblist-item_dt">
                                <span>
                                    <span class="ttl">Fire Alarm On Each Floor</span> {{li.fireAlarmOnEachFloor===
                                    'true' ? 'Yes' : 'No'}}</span>
                                <span>
                                    <span class="ttl">Smoke Detectors Sprinklers</span> {{li.smokeDetectorsSprinklers===
                                    'true' ? 'Yes' : 'No'}}</span>
                            </div>
                            <div class="tblist-item_dt">
                                <span>
                                    <span class="ttl">Hose Reels Each Floor</span> {{li.hoseReelsEachFloor=== 'true' ?
                                    'Yes' : 'No'}}</span>
                            </div>
                            <div class="tblist-item_dt">
                                <span>
                                    <span class="ttl">Lightning Arrestor</span> {{li.lightningArrestor=== 'true' ? 'Yes'
                                    : 'No'}}</span>
                            </div>
                            <div class="tblist-item_dt">
                                <span>
                                    <span class="ttl">Fire Extinguishers Every 30m</span>
                                    {{li.fireExtinguishersEvery30m=== 'true' ? 'Yes' : 'No'}}</span>
                            </div>
                            <div class="tblist-item_dt">
                                <span>
                                    <span class="ttl">Exit Signs Each Floor</span> {{li.exitSignsEachFloor=== 'true' ?
                                    'Yes' : 'No'}}</span>
                            </div>
                            <!-- <div class="tblist-item_xcn" *ngIf="lists.length > 0">
                                <a class="kbk-link-btn hs-tp" data-kbk-tooltip="Related Application" id="create-btn">
                                    Edit </a>
                            </div> -->
                        </li>
                    </ul>
                    <div class="pagnation" *ngIf="lists.length > 0">
                        <div class="pagnation-item">
                            <div class="pagnation-dt" id="tickets-table_info" role="status" aria-live="polite">
                                <span class="ent">{{totalRecords}}</span> <span class="cur">{{startIndex}} -
                                    {{endIndex}}</span>
                            </div>
                        </div>
                        <div class="pagnation-item">
                            <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize"
                                (pageChange)="getPremiumData();">
                            </ngb-pagination>
                        </div>
                    </div>
                    <ul class="tblist" *ngIf="lists.length === 0">
                        <li class="tblist-item">
                            <h2 *ngIf="lists.length === 0" id="exampleModalLabel" class="no-rcrds-found">No records
                                found</h2>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="app-gen-content" *ngIf="isNewInspection">
    <div class="app-gen-header genvline">
        <div class="genline">
            <div>
                <span class="hder" aria-label="header tittle">Inspection of Foundation Works</span>
                <span class="hder-sm" aria-label="header tittle">1. Site identification and Contractor details</span>
            </div>
        </div>

    </div>

    <form [formGroup]="preventionForm" (ngSubmit)="onSubmit()">
        <div class="">
            <h3>Fire Prevention in residential facilities</h3>
            <div class="form-set">
                <div class="kbk-x kbk-wrap-3">
                    <div class="form-input">
                        <label>Two fire extinguishers (kitchen and opposite side)</label>
                        <select formControlName="fireExtinguishersInResidence">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div class="form-input">
                        <label>Fire blanket in the kitchen</label>
                        <select formControlName="fireBlanketInKitchen">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div class="form-input">
                        <label>Two exits per residence</label>
                        <select formControlName="twoExitsPerResidence">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div class="form-input">
                        <label>An emergency exit</label>
                        <select formControlName="emergencyExit">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                </div>
            </div>
            <h3>Disaster Prevention in public buildings</h3>
            <div class="form-set">
                <div class="kbk-x kbk-wrap-3">
                    <div class="form-input">
                        <label>Fire alarm system with an alarm bell on each floor</label>
                        <select formControlName="fireAlarmOnEachFloor">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div class="form-input">
                        <label>Smoke detectors and sprinklers</label>
                        <select formControlName="smokeDetectorsSprinklers">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div class="form-input">
                        <label>Hose reels on each floor</label>
                        <select formControlName="hoseReelsEachFloor">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div class="form-input">
                        <label>Lightning arrestor</label>
                        <select formControlName="lightningArrestor">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div class="form-input">
                        <label>Fire extinguishers every 30m on each floor</label>
                        <select formControlName="fireExtinguishersEvery30m">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div class="form-input">
                        <label>Exit signs on every floor</label>
                        <select formControlName="exitSignsEachFloor">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div class="form-input">
                        <label>Emergency exit on each floor</label>
                        <select formControlName="emergencyExitEachFloor">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div class="form-input">
                        <label>Floor plan on each level</label>
                        <select formControlName="floorPlanEachLevel">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div class="form-input">
                        <label>Sign forbidding the use of elevators in case of fire</label>
                        <select formControlName="signForElevators">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div class="form-input">
                        <label>Landing space for helicopters</label>
                        <select formControlName="landingSpaceHelicopter">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div class="form-input">
                        <label>Evacuation plan</label>
                        <select formControlName="evacuationPlan">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                </div>
            </div>
            <h3>Security Requirements</h3>
            <div class="form-set">
                <div class="kbk-x kbk-wrap-3">
                    <div class="form-input">
                        <label>CCTV cameras</label>
                        <select formControlName="cctvCameras">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div class="form-input">
                        <label>Metal detectors</label>
                        <select formControlName="metalDetectors">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div class="form-input">
                        <label>Luggage scanners</label>
                        <select formControlName="luggageScanners">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div class="form-input">
                        <label>Emergency phone numbers</label>
                        <select formControlName="emergencyPhoneNumbers">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div class="form-input">
                        <label>Fire hydrant nearby</label>
                        <select formControlName="fireHydrantNearby">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div class="form-input">
                        <label>Security evacuation plan</label>
                        <select formControlName="evacuationPlanSecurity">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div class="form-input">
                        <label>Security manager and staff</label>
                        <select formControlName="securityManagerStaff">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div class="form-input">
                        <label>Restricted access yards</label>
                        <select formControlName="restrictedAccess">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div class="form-input">
                        <label>Insurance for building and all users</label>
                        <select formControlName="insuranceBuilding">
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div class="form-input">
                        <div class="form-input">
                            <app-input-file-upload (fileUpload)="getFileToSave($event)"></app-input-file-upload>
                        </div>
                    </div>
                    <div class="form-input">
                        <div class="form-input">
                            <label>Comments</label>
                            <div>
                                <textarea name="comments" formControlName="comments" id="comments" cols="30"
                                    rows="5"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="btns kbk-x-c">
            <button class="kbk-btn kbk-btn-sec" type="button" (click)="cancel()">Cancel</button>
            <button class="kbk-btn kbk-btn-main" type="submit">Submit </button>
        </div>
    </form>
</div>


<!-- <div class="app-dash" >
    <div class="container">
        <div class="app-main">
            <div class="app-welnonte">
                <div class="app-welnote_dtails">
                </div>
            </div>
            
        </div>
    </div>
</div>
<div class="app-gen appl-details" >
    <div class="app-gen-header genvline">
        <section class="genline">
            
        </section>

    </div>
</div> -->