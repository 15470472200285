export const VALUE = {
    // PERTMIT TYPES
    NEW_CONSTRUCTION_PERMIT_CODE: 'NCP',
    INVOICE_TYPE_PERMIT_CODE: 'INVPER',
    INVOICE_PENDING_STATUS_CODE: 'PND',
    EDUCATION_BUILDING_TYPE: 'EDU',
    PROJECT_STATUS_CREATED_CODE: 'PCRTD',
    APPLICATION_STATUS_SUBMIT_CODE: 'SUB',
    APPLICATION_STATUS_RE_SUBMIT_CODE: 'RSMB',
    ADMIN_ROLE_CODE: 'ADM',
    STAFF_ROLE_CODE: 'STF',
    STAFF_REVIEWER_ROLE_CODE: 'STFRVWR',
    PROJECT_ASSIGNED_CODE: 'PASGD',
}