import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, map } from "rxjs";
import { CrudOperations } from "./crud-operation";
import { HeaderOptions } from "./header-options/header-options";


@Injectable({
    providedIn: 'root'
})

export class CrudService<T, ID> implements CrudOperations<T, ID> {
    private _loading$ = new BehaviorSubject<boolean>(true);
    private _search$ = new Subject<void>();
    private _countries$ = new BehaviorSubject<any[]>([]);
    private _total$ = new BehaviorSubject<number>(0);
    public _state: any = {
        page: 1,
        pageSize: 8,
        searchTerm: "",
        filterByDate: "",
        filterEndDate: "",
        sortColumn: "",
        sortDirection: "",
        startIndex: 0,
        endIndex: 9,
        totalRecords: 0,
        status: "",
        payment: "",
        date: "",
    };



    constructor(
        protected _http: HttpClient,
        @Inject(String) protected _base: string,
        protected headerOption: HeaderOptions
    ) { }


    set searchTerm(searchTerm: string) {
        this._set({ searchTerm });
    }

    set filterByDate(filterByDate: any) {
        this._set({ filterByDate });
    }

    set filterEndDate(filterEndDate: any) {
        this._set({ filterEndDate });
    }

    set status(status: any) {
        this._set({ status });
    }

    set page(page: number) {
        this._set({ page });
    }
    set pageSize(pageSize: number) {
        this._set({ pageSize });
    }


    public _set(patch: Partial<any>) {
        Object.assign(this._state, patch);
        this._search$.next();
    }

    set date(date: any) {
        this._set({ date });
    }

    get date() {
        return this._state.date;
    }


    get total$() {
        return this._total$.asObservable();
    }
    get loading$() {
        return this._loading$.asObservable();
    }
    get page() {
        return this._state.page;
    }
    get pageSize() {
        return this._state.pageSize;
    }
    get searchTerm() {
        return this._state.searchTerm;
    }
    get filterByDate() {
        return this._state.filterByDate;
    }

    get filterEndDate() {
        return this._state.filterEndDate;
    }
    
    
    get startIndex() {
        return this._state.startIndex;
    }
    get endIndex() {
        return this._state.endIndex;
    }
    get totalRecords() {
        return this._state.totalRecords;
    }
    get status() {
        return this._state.status;
    }


    save(t: T): Observable<T> {
        return this._http.post<T>(this._base, t, this.headerOption.httpOptions)
            .pipe(map((data: any) => {
                return data;
            }),
            );
    }


    update(id: ID, t: T): Observable<T> {
        return this._http.put<T>(this._base + "/" + id, t, this.headerOption.httpOptions)
            .pipe(map((data: any) => {
                return data;
            }),

            );
    }

    findOne(id: ID): Observable<T> {
        return this._http.get<T>(this._base + "/" + id, this.headerOption.httpOptions)
            .pipe(map((data: any) => {
                return data;
            }),

            );
    }

    findAll(): Observable<T[]> {
        return this._http.get<T[]>(this._base, this.headerOption.httpOptions)
            .pipe(map((data: any) => {
                return data;
            }),

            );
    }

    delete(id: ID): Observable<T> {
        return this._http.delete<T>(this._base + '/' + id, this.headerOption.httpOptions)
            .pipe(map((data: any) => {
                return data;
            }),

            );
    }



    // methodsWithPath


    saveAssetWithPath(t: any, url: any): Observable<T> {
        return this._http.post<T>(url, t, this.headerOption.httpOptions)

            .pipe(map((data: any) => {
                return data;
            }),

            );
    }


    saveAssetWithPathFormData(t: any, url: any): Observable<T> {
        return this._http.post<T>(url, t, this.headerOption.httpFormDataOptions)

            .pipe(map((data: any) => {
                return data;
            }),

            );
    }



    updateAssetWithPathFormData(t: any, url: any): Observable<T> {
        return this._http.put<T>(url, t, this.headerOption.httpFormDataOptions)

            .pipe(map((data: any) => {
                return data;
            }),

            );
    }



    updateAssetWithPath(id: ID, t: any, url: any): Observable<T> {
        return this._http.put<T>(url + "/" + id, t, this.headerOption.httpOptions)
            .pipe(map((data: any) => {
                return data;
            }),

            );
    }


    patchAssetWithPath(id: ID, t: any, url: any): Observable<T> {
        return this._http.patch<T>(url + "/" + id, t, this.headerOption.httpOptions)
            .pipe(map((data: any) => {
                return data;
            }),
            );
    }

    patchAssetWithoutParams(t: any, url: any): Observable<T> {
        return this._http.patch<T>(url, t, this.headerOption.httpOptions)
            .pipe(map((data: any) => {
                return data;
            }),
            );
    }

    updateAssetWithoutParams(t: any, url: any): Observable<T> {
        return this._http.put<T>(url, t, this.headerOption.httpOptions)
            .pipe(map((data: any) => {
                return data;
            }),

            );
    }

    updateAssetWithPathAndQueryParams(id: ID, t: any, url: any): Observable<T> {
        return this._http.put<T>(url + "?Id=" + id, t, this.headerOption.httpOptions)
            .pipe(map((data: any) => {
                return data;
            }),

            );
    }

    saveWithPath(t: T, url: any): Observable<T> {
        return this._http.post<T>(url, t, this.headerOption.httpOptions)

            .pipe(map((data: any) => {
                return data;
            }),

            );
    }


    patchWithPath(t: T, url: any): Observable<T> {
        return this._http.patch<T>(url, t, this.headerOption.httpOptions)

            .pipe(map((data: any) => {
                return data;
            }),

            );
    }


    updateWithPath(id: ID, t: T, url: any): Observable<T> {
        return this._http.put<T>(url + "/" + id, t, this.headerOption.httpOptions)
            .pipe(map((data: any) => {
                return data;
            }),

            );
    }

    updateWithUrlOnly(url: any): Observable<T> {
        return this._http.put<T>(url, JSON.stringify(''), this.headerOption.httpOptions)
            .pipe(map((data: any) => {
                return data;
            }),

            );
    }


    updateWithoutParam(t: T, url: any): Observable<T> {
        return this._http.put<T>(url, t, this.headerOption.httpOptions)
            .pipe(map((data: any) => {
                return data;
            }),

            );
    }

    updateWithPathAndQueryParams(id: ID, t: T, url: any): Observable<T> {
        return this._http.put<T>(url + "?Id=" + id, t, this.headerOption.httpOptions)
            .pipe(map((data: any) => {
                return data;
            }),

            );
    }

    findOneWithPath(id: ID, url: any): Observable<T> {
        return this._http.get<T>(url + "/" + id, this.headerOption.httpOptions)
            .pipe(map((data: any) => {
                return data;
            }),

            );
    }

    // findOneWithPath(id: ID, url: any): Observable<any> {
    //     return this._http.get<T>(url + "/" + id, this.headerOption.httpOptions);
    // }

    findAllWithPath(url: any): Observable<T> {
        return this._http.get<T>(url, this.headerOption.httpOptions)
            .pipe(map((data: any) => {
                return data;
            }),

            );
    }

    deleteWithPath(id: ID, url: any): Observable<T> {
        return this._http.delete<T>(url + '/' + id, this.headerOption.httpOptions)
            .pipe(map((data: any) => {
                return data;
            }),

            );
    }

    deleteWithPathNoId(url: any): Observable<T> {
        return this._http.delete<T>(url, this.headerOption.httpOptions)
            .pipe(map((data: any) => {
                return data;
            }),

            );
    }

    deleteWithPathAndQueryParams(id: ID, url: any): Observable<T> {
        return this._http.delete<T>(url + '?Id=' + id, this.headerOption.httpOptions)
            .pipe(map((data: any) => {
                return data;
            }),

            );
    }

}
