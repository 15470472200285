import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { jwtDecode } from 'jwt-decode';
import { ApplicationService } from 'src/app/application/services/application.service';
import { SessionService } from 'src/app/authentication-services/session.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-general-inspection',
  templateUrl: './general-inspection.component.html',
  styleUrls: ['./general-inspection.component.scss']
})
export class GeneralInspectionComponent {
  @Input() inputData: any;
  @Output() backToParent = new EventEmitter();
  preventionForm!: FormGroup;
  projectDetails: any = {};
  paramsId: any = '';
  page = 1;
  pageSize = 10;
  startIndex = 0;
  endIndex = 9;
  totalRecords = 0;
  collectionSize = 0;
  content?: any;
  total!: number;
  fullList: any[] = [];
  filteredList: any[] = [];
  lists: any[] = [];
  isNewInspection: boolean = false;
  currentUser: any;
  fileData: any = {};



  siteStatuses: any[] = [];

  constructor(private fb: FormBuilder,
    public applicationService: ApplicationService,
    private sessionService: SessionService,) {
    this.currentUser = this.sessionService.getSession();
    this.currentUser.userId = (jwtDecode(this.currentUser.data.token.access_token) as any).UserId;



    this.applicationService.findAllWithPath(environment.applicationUrl + 'certificate/siteStatus')
      .subscribe(
        data => { this.siteStatuses = data; }
      )
  }

  ngOnDestroy() {
    this.applicationService.searchTerm = '';
  }

  ngOnInit(): void {
    console.log(this.inputData);
    this.preventionForm = this.fb.group({
      UPI: [this.inputData.applicationDetail.applications?.projects.upi, Validators.required],
      reason: ['', Validators.required],
      certificateNumber: [this.inputData.applicationDetail?.certificateNumber],
      certificateId: [this.inputData.applicationDetail?.id],
      siteStatusId: ['', Validators.required],
      comments: [''],
      applicationId: [this.inputData.applicationDetail.applications?.id, Validators.required],
      userId: [this.currentUser.userId, Validators.required]
    });
    this.loadList();

  }

  getFileToSave(event: any) {
    this.fileData = event;
  }



  loadList() {
    this.applicationService.findAllWithPath(environment.applicationUrl + 'certificate/randomInspection/certificate/'
      + this.inputData?.applicationDetail?.id
    ).subscribe(
      dataItem => {
        this.lists = dataItem;
      }
    )
  }

  getPremiumData() {
    this.filterAndPaginate();
  }

  filterAndPaginate() {
    const filtered = this.fullList.filter(item => this.applyFilter(item));
    this.filteredList = filtered;
    this.totalRecords = filtered.length;
    this.startIndex = (this.page - 1) * this.pageSize + 1;
    this.endIndex = (this.page - 1) * this.pageSize + this.pageSize;
    if (this.endIndex > this.totalRecords) {
      this.endIndex = this.totalRecords;
    }
    this.lists = filtered.slice(this.startIndex - 1, this.endIndex);
  }

  applyFilter(item: any): boolean {
    if (!this.applicationService.searchTerm) return true;
    const term = this.applicationService.searchTerm.toLowerCase();
    return Object.values(item).some(val =>
      String(val).toLowerCase().includes(term)
    );
  }


  onSearchTermChange(term: string): void {
    this.applicationService.searchTerm = term;
    this.filterAndPaginate();
  }

  cancel() {
    this.isNewInspection = !this.isNewInspection;
  }

  newInspection(event: any) {
    this.cancel()
    if (event) {

    }
  }

  onSubmit() {
    let formData = new FormData();
    formData.append("UPI ", this.preventionForm.value.UPI + '');
    formData.append("reason ", this.preventionForm.value.reason + '');
    formData.append("certificateId ", this.preventionForm.value.certificateId + '');
    formData.append("siteStatusId ", this.preventionForm.value.emergencyExit + '');
    formData.append("userId", this.currentUser.userId + '');


    const fileList: FileList = this.fileData.file;
    if (fileList && fileList[0]) {
      const file: File = fileList[0];
      this.fileData.fileNameDisplay = file?.name
      formData.append('file', file, file?.name);
    }

    // this.applicationService.saveAssetWithPath(this.preventionForm.value, environment.applicationUrl + 'certificate/randomInspection')
    this.applicationService.saveAssetWithPathFormData(formData, environment.applicationUrl + 'certificate/randomInspection')
      .subscribe(
        data => {
          this.cancel();
          this.loadList();
        }, error => { })
  }



  closeList() {
    this.backToParent.emit();
  }
}
