import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { jwtDecode } from 'jwt-decode';
import { AppConfig } from 'src/app/app.config';
import { ApplicationService } from 'src/app/application/services/application.service';
import { SessionService } from 'src/app/authentication-services/session.service';
import { VALUE } from 'src/app/core/value-enum/value-enum';
import { APIURLPATH } from 'src/app/shared/services/url-path';
import { NOTIFICATION_COLOR, UtilService } from 'src/app/shared/services/util.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-related-permit-type-application',
  templateUrl: './related-permit-type-application.component.html',
  styleUrls: ['./related-permit-type-application.component.scss']
})
export class RelatedPermitTypeApplicationComponent {
  @ViewChild('mySelect') mySelect!: ElementRef<HTMLSelectElement>;
  isSelectDisabled = true;
  @Input() inputData: any = {};
  userForm!: UntypedFormGroup;
  pageForm!: UntypedFormGroup;
  categoryTypes: any[] = [];
  buildingTypes: any[] = [];
  permitTypes: any[] = [];
  loading: boolean = false;
  currentUser: any;
  params: any = {};
  upiInfo: any = {};
  applicationDetails: any = {};
  formAccess: any = {};
  submitted: boolean = false;
  technologySurveys: any[] = [];
  agencies: any[] = [];
  doYouWantToDoItYouself: boolean = true;
  doYouWantToAssignToEngineer: boolean = false;
  isAssociated: boolean = false;
  outputData: any = {};

  checkValue(event: any) {
    if (event.target.value < 0) {
      event.target.value = 0;
    }
  }

  constructor(
    private applicationService: ApplicationService,
    private formBuilder: UntypedFormBuilder,
    private utilService: UtilService,
    private appConfig: AppConfig,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private sessionService: SessionService
  ) {
    this.currentUser = this.sessionService.getSession();
    this.currentUser.userId = (jwtDecode(this.currentUser.data.token.access_token) as any).UserId;
    this.loadLookups();
    this.route.params.subscribe((params: any) => {
      console.log(params)
      // this.params.projectId = params.projectId;
      // this.params.permitTypeCode = params.permitCode;

      this.params = params;


    })



  }



  checkAssociated(event: any) {
    if (this.params.projectId !== '0') {
      this.outputData.applicationId = this.params.projectId;
      this.outputData.combiningPlotSize = this.applicationDetails.combiningPlotSize;
      this.outputData.plotSize = this.applicationDetails.plotSize;
      this.isAssociated = !this.isAssociated;
      this.openModal(event, 'lg');
    } else {
      this.utilService.showNotification(NOTIFICATION_COLOR.error, "Project identifier is not yet generated", "top", "right");
    }
  }


  closeAssociatedPopup(event: any) {
    this.modalService.dismissAll();
    this.isAssociated = !this.isAssociated;
    // update value of percentage
    this.checkIfUPIhasAlreadyProject();
  }
  openModal(content: any, sizeParams: any) {
    this.modalService.open(content, { size: sizeParams, centered: true });
  }

  ngOnInit(): void {
    this.refresh();
    this.formAccessData();
    if (this.params.projectId === '0') {
      this.userForm = this.formBuilder.group({
        applicationId: [""],
        permitTypeId: ["", [Validators.required]],
        categoryTypeId: ["", [Validators.required]],
        buildTypeId: ["", [Validators.required]],
        permitTypeCode: [""],
        applicationStatusId: [""], // hard coded
        userId: [this.currentUser.userId],
        agencyId: [""],
        projectId: ["", [Validators.required]],
        agencyCode: ["", [Validators.required]],
        categoryCode: [''],
        usedPermitType: [''],
        certificateNumberEIA: [''],
        applicationStatusCode: [''],
        //
        waterConsumption: ["", [Validators.required]],
        electricityConsumption: ["", [Validators.required]],
        DistanceToTheNearestLandIn: ["", [Validators.required]],
        ProjectCostInUSD: ["", [Validators.required]],
        ProjectCostInRwf: ["", [Validators.required]],
        buildUpArea: ["", [Validators.required]],
        numberOfFloor: ["", [Validators.required]],    // help to calculate amount
        grossFloorArea: ["", [Validators.required]],
        numberOfParkingSpace: [""],
        priceOfDwellingUnitRwf: ["", [Validators.required]],
        capacityInformation: ["", [Validators.required]],
        numberOfDwellingUnits: [""],
        DescriptionOfOperation: [""],
        technologySurveyId: [""],
        isEIAVerified: [false],
        // optional
        plotSize: [0],
        upi: [""],
        // optional
        percentageSpaceUse: ["", [Validators.required]],
      }, { validator: this.buildUpAreaValidator });
      this.userForm.controls['plotSize'].setValue(this.upiInfo.size);
      this.focusOutFunction();
      this.checkIfUPIhasAlreadyProject();
    } else {
      // Mean upi already in BPMIS
      this.checkIfUPIhasAlreadyProject();
    }
  }

  checkIfUPIhasAlreadyProject() {
    // Call project by upi
    if (this.params.projectId === '0') {
      this.upiInfo = JSON.parse(localStorage.getItem(this.appConfig.UPI_NEW_INFO) as any);
      console.log(this.upiInfo);
      // this.getProjectByUPI(this.upiInfo.upi);
    } else {
      this.loading = true;




      this.applicationService.findAllWithPath(environment.applicationUrl + 'application/project/' + this.params.projectId)
        .subscribe(
          data => {
            this.applicationDetails = data;
            if (this.applicationDetails) {
              console.log(this.applicationDetails);
              this.userForm.controls['projectId'].setValue(this.applicationDetails?.id);
              this.userForm.controls['agencyId'].setValue(this.applicationDetails?.agencyId);
              this.userForm.controls['plotSize'].setValue(this.applicationDetails?.plotSize);
              this.userForm.controls['upi'].setValue(this.applicationDetails?.upi)
              console.log(this.applicationDetails);
              if (this.applicationDetails?.districtCode) {
                this.applicationService.findAllWithPath(environment.authUrl + 'user-management/agencyDistricts/ByDistrictCode/' + this.applicationDetails.districtCode)
                  .subscribe(
                    agencyData => {
                      this.userForm.controls['agencyCode'].setValue(agencyData.code);
                      this.loading = false;
                    },
                    error => { this.loading = false; }
                  )
              } else {
                this.utilService.showNotification(NOTIFICATION_COLOR.error, "District code is missing", "top", "right");
              }

              this.checkIfProjectUPIHasAssociatedUpi(this.applicationDetails);
              // Call exiting application by project id and permit type
              this.getExistingApplicationAndPermitType();
              // Call exiting application by project id and permit type
            } else {
              this.utilService.showNotification(NOTIFICATION_COLOR.error, "Project you are looking for does not found", "top", "right");
            }
          },
          error => { this.loading = false; }
        )
    }
  }

  getExistingApplicationAndPermitType() {
    // this.applicationService.findAllWithPath(environment.applicationUrl + 'application/permitType/code/search?search=' + VALUE.NEW_CONSTRUCTION_PERMIT_CODE)
    //   .subscribe(
    //     data => {
    this.userForm.controls['usedPermitType'].setValue(this.params.permitTypeId);
    this.applicationService.findAllWithPath(environment.applicationUrl + 'application/application/' + this.params.projectId + '/permit-type/' + this.userForm.value.usedPermitType)
      .subscribe(
        data => {
          console.log(data)
          if (data.length > 0) {

            this.formAccess.ApplicationAlreadyExists = true;
            this.fillTheDataTotheForm(data[0]);
            // if data present means that application already existis
            // this.formAccess.isApplicationExists = true;
          } else {
            this.userForm.controls['plotSize'].setValue(this.applicationDetails.plotSize);
            this.formAccess.ApplicationAlreadyExists = false;
          }
        }
      )
    //   },
    //   error => { }
    // )
  }


  checkIfProjectUPIHasAssociatedUpi(projectDetails: any) {
    if (projectDetails.isAssociatedUpi && this.params.projectId !== '0') {
      this.applicationService.findAllWithPath(`${environment.applicationUrl}application/associatedUPI/ByProject/${projectDetails.id}`)
        .subscribe(dataItem => {
          // Initialize combiningPlotSize to zero
          let totalPlotSize = 0;

          // Accumulate plot sizes
          dataItem.forEach((element: any) => {
            totalPlotSize += +element.plotSize;
          });

          // Update the combiningPlotSize and percentageSpaceUse once
          this.userForm.controls['combiningPlotSize'].setValue(totalPlotSize);

          const buildUpArea = this.userForm.value.buildUpArea || 1; // Avoid division by zero
          const percentageSpaceUse = ((buildUpArea / totalPlotSize) * 100).toFixed(2);
          this.userForm.controls['percentageSpaceUse'].setValue(percentageSpaceUse);
        });
    }
  }


  buildUpAreaValidator: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
    const plotSize = control.get('plotSize');
    // const plotSize = this.upiInfo.size;
    const buildUpArea = control.get('buildUpArea');
    const percentageSpaceUse: any = control.get('percentageSpaceUse');
    if (plotSize && buildUpArea && plotSize.value && buildUpArea.value) {
      if (buildUpArea.value > plotSize.value) {
        console.log('Hello there is greater that plot size');
        return { 'greaterThanPlotSize': true };
      }
    }
    return null;
  };




  changeForm() {
    this.doYouWantToAssignToEngineer = false;
    this.pageForm.controls['doYouWantToAssignToEngineer'].setValue(false);
    this.doYouWantToDoItYouself = true;
    this.pageForm.controls['doYouWantToDoItYouself'].setValue(true);

  }

  changeAssignForm() {
    this.doYouWantToAssignToEngineer = true;
    this.pageForm.controls['doYouWantToAssignToEngineer'].setValue(true);
    this.doYouWantToDoItYouself = false;
    this.pageForm.controls['doYouWantToDoItYouself'].setValue(false);

  }





  fillTheDataTotheForm(data: any) {
    console.log(data);

    this.userForm.controls['applicationId'].setValue(data?.id);
    this.userForm.controls['permitTypeId'].setValue(data?.permitTypes?.id);
    this.userForm.controls['categoryTypeId'].setValue(data?.categoryTypes?.id);
    this.userForm.controls['buildTypeId'].setValue(data?.buildTypes?.id);
    this.userForm.controls['permitTypeCode'].setValue(data?.permitTypes?.code);
    this.userForm.controls['applicationStatusId'].setValue(data?.applicationStatus?.id);
    this.userForm.controls['applicationStatusCode'].setValue(data?.applicationStatus?.code);
    // this.userForm.controls['userId'].setValue(data?.userDetails?.id);
    // this.userForm.controls['agencyId'].setValue(data?.agency?.id);
    this.userForm.controls['agencyId'].setValue(data?.agencyId);
    this.userForm.controls['projectId'].setValue(data?.projects?.id);
    this.userForm.controls['agencyCode'].setValue(this.agencies.length > 0 ? this.agencies.find((x: any) => x.id === data?.agencyId).code : data?.agencyId);
    this.userForm.controls['categoryCode'].setValue(data?.categoryTypes?.code);
    // this.userForm.controls['usedPermitType'].setValue(data.permitType.id);
    this.userForm.controls['certificateNumberEIA'].setValue(data?.certificateNumberEIA);
    this.userForm.controls['waterConsumption'].setValue(data?.waterConsumption);
    this.userForm.controls['electricityConsumption'].setValue(data?.electricityConsumption);
    this.userForm.controls['DistanceToTheNearestLandIn'].setValue(data?.DistanceToTheNearestLandIn);
    this.userForm.controls['ProjectCostInUSD'].setValue(data?.ProjectCostInUSD);
    this.userForm.controls['ProjectCostInRwf'].setValue(data?.ProjectCostInRwf);
    this.userForm.controls['buildUpArea'].setValue(data?.buildUpArea);
    this.userForm.controls['numberOfFloor'].setValue(data?.numberOfFloor);
    this.userForm.controls['grossFloorArea'].setValue(data?.grossFloorArea);
    this.userForm.controls['numberOfParkingSpace'].setValue(data?.numberOfParkingSpace);
    this.userForm.controls['priceOfDwellingUnitRwf'].setValue(data?.priceOfDwellingUnitRwf);
    this.userForm.controls['capacityInformation'].setValue(data?.capacityInformation);
    this.userForm.controls['numberOfDwellingUnits'].setValue(data?.numberOfDwellingUnits);
    this.userForm.controls['DescriptionOfOperation'].setValue(data?.DescriptionOfOperation);
    // optional
    this.userForm.controls['plotSize'].setValue(data?.projects?.plotSize);
    // optional
    this.userForm.controls['percentageSpaceUse'].setValue(data?.percentageSpaceUse);

    this.userForm.controls['priceOfDwellingUnitRwf'].setValue(data?.priceOfDwellingUnitRwf);


    this.userForm.controls['technologySurveyId'].setValue(data?.technologySurveys?.id);
    // this.userForm.controls['upi'].setValue(data?.upi);

    if (this.userForm.value.buildTypeId) {
      this.getBuildTypeObject('');
      this.calculateNumberOfPeople();
    }



    // this.focusOutFunction()
  }

  checkTemporalPermit() {
    let permitType = this.permitTypes.find((x: any) => x.id === this.userForm.value.permitTypeId);
    if (permitType.code === 'TSAP') {
      this.findCategoryByCode('CAT1');
      this.userForm.controls['categoryCode'].setValue('CAT1');
    }
  }



  // 1
  getBuildTypeObject(event: any) {
    let buildType: any = this.buildingTypes.find((x: any) => x.id === this.userForm.value.buildTypeId);
    // 'FAI' || 'EDU' || 'ASMLY' || 'INSTNAL'
    if (buildType.code === 'FAI' ||
      buildType.code === VALUE.EDUCATION_BUILDING_TYPE ||
      buildType.code === 'ASMB'
      || buildType.code === 'INSTNAL'
      || buildType.code === 'MEM'
      || buildType.code === 'STRG'
      || buildType.code === 'MISLNS'
      || buildType.code === 'INST'
    ) {
      this.findCategoryByCode('CAT5');
      this.userForm.controls['categoryCode'].setValue('CAT5');
      // this.findCategoryByCode('CAT1');
      // this.userForm.controls['categoryCode'].setValue('CAT1');
    } else {
      this.calculateToGetCategory()
    }

    this.checkTemporalPermit();
  }

  // 2
  calculateToGetCategory() {
    let buildTypeCode =
      this.buildingTypes.find((buildingType: any) =>
        buildingType.id == this.userForm.value.buildTypeId);
    if (+this.userForm.value.capacityInformation <= 500 &&
      +this.userForm.value.numberOfFloor >= 2) {
      this.findCategoryByCode('CAT4');
      this.userForm.controls['categoryCode'].setValue('CAT4');
    } else

      // if (
      //   +this.userForm.value.numberOfFloor === 1 &&
      //   (+this.userForm.value.buildUpArea >= 200 && +this.userForm.value.buildUpArea <= 1500
      //   )) {
      if ((this.userForm.value.numberOfFloor &&
        this.userForm.value.buildUpArea) &&
        (+this.userForm.value.numberOfFloor === 1
          && +this.userForm.value.numberOfFloor < 1) &&
        (+this.userForm.value.buildUpArea >= 200
          && +this.userForm.value.buildUpArea <= 1500)
      ) {

        this.findCategoryByCode('CAT3');
        this.userForm.controls['categoryCode'].setValue('CAT3');
        this.formAccess.doYouWantToShareToEngineer = true;
      } else
        // if (
        //   this.userForm.value.numberOfFloor === 0 && this.userForm.value.buildUpArea <= 200) {
        if (
          (this.userForm.value.numberOfFloor !== undefined && this.userForm.value.buildUpArea !== undefined) &&
          (+this.userForm.value.numberOfFloor === 0) && (+this.userForm.value.buildUpArea <= 200)

        ) {
          this.findCategoryByCode('CAT2');
          this.userForm.controls['categoryCode'].setValue('CAT2');
        }
        else {
          // this.findCategoryByCode('CAT1');
          // this.userForm.controls['categoryCode'].setValue('CAT1');
          this.findCategoryByCode('CAT2');
          this.userForm.controls['categoryCode'].setValue('CAT2');
        }
    this.checkTemporalPermit();
  }
  // 3
  focusOutFunction() {
    console.log(this.userForm.value.buildUpArea);
    console.log(this.userForm.value.plotSize);
    // this.userForm.controls['percentageSpaceUse'].setValue(((this.userForm.value.buildUpArea / this.upiInfo.size) * 100).toFixed(2));
    this.userForm.controls['percentageSpaceUse'].setValue(((this.userForm.value.buildUpArea / (this.params.projectId === '0' ? this.upiInfo.size : this.userForm.value.plotSize)) * 100).toFixed(2));

    if ((this.userForm.value.numberOfFloor && this.userForm.value.buildUpArea) &&
      (+this.userForm.value.numberOfFloor === 1 && +this.userForm.value.numberOfFloor < 1) &&
      (+this.userForm.value.buildUpArea >= 200 && +this.userForm.value.buildUpArea <= 1500)
    ) {
      console.log('Category 3')
      this.findCategoryByCode('CAT3');
      this.userForm.controls['categoryCode'].setValue('CAT3');
    } else if (
      (this.userForm.value.numberOfFloor !== undefined && this.userForm.value.buildUpArea !== undefined) &&
      (+this.userForm.value.numberOfFloor === 0) && (+this.userForm.value.buildUpArea <= 200)
    ) {
      console.log('Category 2')
      this.findCategoryByCode('CAT2');
      this.userForm.controls['categoryCode'].setValue('CAT2');
    } else {
      this.getBuildTypeObject('');
    }
    this.checkTemporalPermit();
  }



  calculateNumberOfPeople() {
    let buildType = this.buildingTypes.find((x: any) => x.id === this.userForm.value.buildTypeId);
    console.log(this.userForm.value.capacityInformation)
    if (+this.userForm.value.capacityInformation > 500 && (buildType.code === 'CMRC'
      || buildType.code === 'MRCTN' || buildType.code === 'RES' || buildType.code === 'MIXD'
    )) {
      this.findCategoryByCode('CAT5');
      this.userForm.controls['categoryCode'].setValue('CAT5');
    } else {
      this.calculateToGetCategory();
    }
    this.checkTemporalPermit();
  }

  calculateNumberOfFlow() {
    if (this.userForm.value.numberOfFloor && this.userForm.value.buildUpArea &&
      +this.userForm.value.numberOfFloor === 1 &&
      (+this.userForm.value.buildUpArea >= 200 &&
        +this.userForm.value.buildUpArea <= 1500)
    ) {
      this.findCategoryByCode('CAT3');
      this.userForm.controls['categoryCode'].setValue('CAT3');
    } else if (

      // (this.userForm.value.numberOfFloor && this.userForm.value.buildUpArea) &&
      // (+this.userForm.value.numberOfFloor === 0) && (+this.userForm.value.buildUpArea <= 200)
      (this.userForm.value.numberOfFloor !== undefined
        && this.userForm.value.buildUpArea !== undefined) &&
      (+this.userForm.value.numberOfFloor === 0) &&
      (+this.userForm.value.buildUpArea <= 200)

    ) {
      this.findCategoryByCode('CAT2');
      this.userForm.controls['categoryCode'].setValue('CAT2');
    } else {

      this.getBuildTypeObject('');
    }


    if (this.userForm.value.buildUpArea && this.userForm.value.numberOfFloor) {
      this.userForm.controls['grossFloorArea'].setValue((this.userForm.value.buildUpArea) * ((+this.userForm.value.numberOfFloor) + 1));
    }

    this.checkTemporalPermit();
  }


  findCategoryByCode(code: any) {
    this.applicationService.findAllWithPath(environment.applicationUrl + 'application/categoryType/code/search?search=' + code)
      .subscribe(data => {
        this.userForm.controls['categoryTypeId'].setValue(data.items[0].id);
        this.checkTemporalPermit();
      })
  }


  updateApplication() {
    let dataToSubmit = {
      "waterConsumption": +this.userForm.value.waterConsumption,
      "electricityConsumption": +this.userForm.value.electricityConsumption,
      "DistanceToTheNearestLandIn": +this.userForm.value.DistanceToTheNearestLandIn,
      "ProjectCostInUSD": +this.userForm.value.ProjectCostInUSD,
      "ProjectCostInRwf": +this.userForm.value.ProjectCostInRwf,
      "buildUpArea": +this.userForm.value.buildUpArea,
      "numberOfFloor": +this.userForm.value.numberOfFloor,
      "grossFloorArea": +this.userForm.value.grossFloorArea,
      "numberOfParkingSpace": +this.userForm.value.numberOfParkingSpace,
      "priceOfDwellingUnitRwf": +this.userForm.value.priceOfDwellingUnitRwf,
      "capacityInformation": +this.userForm.value.capacityInformation,
      "numberOfDwellingUnits": this.userForm.value.numberOfDwellingUnits ? +this.userForm.value.numberOfDwellingUnits : 0,
      "DescriptionOfOperation": this.userForm.value.DescriptionOfOperation,
      "percentageSpaceUse": this.userForm.value.percentageSpaceUse,
      "userId": this.currentUser.userId,
      "certificateNumberEIA": this.userForm.value.certificateNumberEIA,
      "projectId": this.userForm.value.projectId,
      "permitTypeId": this.userForm.value.permitTypeId,
      "categoryTypeId": this.userForm.value.categoryTypeId,
      "buildTypeId": this.userForm.value.buildTypeId,
      "technologySurveyId": this.userForm.value.technologySurveyId,
      "agencyId": this.userForm.value.agencyId,
      "applicationStatusId": this.userForm.value.applicationStatusId,
      "permitTypeCode": this.userForm.value.permitTypeCode,
      "agencyCode": this.userForm.value.agencyCode,
      "upi": this.userForm.value.upi
    }

    this.applicationService.patchAssetWithoutParams(dataToSubmit, environment.applicationUrl + 'application/application/' + this.userForm.value.applicationId)
      .subscribe(data => {
        this.onNext();
      })
  }


  onNext() {
    console.log(this.userForm.value);
    // this.router.navigate(['/account/application/resume-application-attachment/NCP/', this.getUrlParamater()]);
    this.router.navigate(['/account/application/resume-application-attachment/' + this.userForm.value.permitTypeCode + '/', this.getUrlParamater()]);

  }

  onSubmit() {
    if (this.userForm.invalid) {
      this.submitted = false;
      this.utilService.showNotification(NOTIFICATION_COLOR.error, 'Fill all required', "bottom", "center")
      return;
    } else {
      if (this.userForm.value.applicationId && this.userForm.value.applicationStatusCode === 'UNCRN') {
        this.updateApplication();
      } else {
        this.submitted = true;
        this.applicationService.findAllWithPath(environment.applicationUrl + 'application/applicationStatus/code/search?search=' + 'PND')
          .subscribe(
            data => {
              this.userForm.controls['applicationStatusId'].setValue(data.items[0].id);
              this.confirmSubmit();
            }, error => {
              this.submitted = false;
              this.utilService.showNotification(NOTIFICATION_COLOR.error, "Application status not found", "bottom", "center");
            });
      }
    }
  }


  confirmSubmit() {

    let permitTypeCode = this.permitTypes.find((x: any) => x.id === this.userForm.value.permitTypeId).code;
    this.userForm.controls['permitTypeCode'].setValue(permitTypeCode);

    this.userForm.value.numberOfFloor = this.userForm.controls['numberOfFloor'].setValue(this.userForm.value.numberOfFloor);
    this.userForm.value.electricityConsumption = this.userForm.controls['electricityConsumption'].setValue(this.userForm.value.electricityConsumption);
    this.userForm.value.DistanceToTheNearestLandIn = this.userForm.controls['DistanceToTheNearestLandIn'].setValue(this.userForm.value.DistanceToTheNearestLandIn);

    // const originalProjectCostInUSD = this.userForm.value.ProjectCostInUSD;
    // const originalProjectCostInRwf = this.userForm.value.ProjectCostInRwf;
    // const originalPriceOfDwellingUnitRwf = this.userForm.value.priceOfDwellingUnitRwf;


    // this.userForm.value.ProjectCostInUSD = this.userForm.controls['ProjectCostInUSD'].setValue(this.utilService.returnToNumber(this.userForm.value.ProjectCostInUSD));
    // this.userForm.value.ProjectCostInRwf = this.userForm.controls['ProjectCostInRwf'].setValue(this.utilService.returnToNumber(this.userForm.value.ProjectCostInRwf));
    // this.userForm.value.priceOfDwellingUnitRwf = this.userForm.controls['priceOfDwellingUnitRwf'].setValue(this.utilService.returnToNumber(this.userForm.value.priceOfDwellingUnitRwf));

    // try {
    //   // Attempt to convert to number and set the value
    //   const convertedProjectCostInUSD = this.utilService.returnToNumber(originalProjectCostInUSD);
    //   if (isNaN(convertedProjectCostInUSD)) throw new Error();
    //   this.userForm.controls['ProjectCostInUSD'].setValue(convertedProjectCostInUSD);

    //   const convertedProjectCostInRwf = this.utilService.returnToNumber(originalProjectCostInRwf);
    //   if (isNaN(convertedProjectCostInRwf)) throw new Error();
    //   this.userForm.controls['ProjectCostInRwf'].setValue(convertedProjectCostInRwf);

    //   const convertedPriceOfDwellingUnitRwf = this.utilService.returnToNumber(originalPriceOfDwellingUnitRwf);
    //   if (isNaN(convertedPriceOfDwellingUnitRwf)) throw new Error();
    //   this.userForm.controls['priceOfDwellingUnitRwf'].setValue(convertedPriceOfDwellingUnitRwf);
    // } catch (error) {
    //   // If conversion fails, revert to original values
    //   this.userForm.controls['ProjectCostInUSD'].setValue(originalProjectCostInUSD);
    //   this.userForm.controls['ProjectCostInRwf'].setValue(originalProjectCostInRwf);
    //   this.userForm.controls['priceOfDwellingUnitRwf'].setValue(originalPriceOfDwellingUnitRwf);
    // }

    this.userForm.value.buildUpArea = this.userForm.controls['buildUpArea'].setValue(this.userForm.value.buildUpArea);
    this.userForm.value.grossFloorArea = this.userForm.controls['grossFloorArea'].setValue(this.userForm.value.grossFloorArea);
    this.userForm.value.numberOfParkingSpace = this.userForm.controls['numberOfParkingSpace'].setValue(this.userForm.value.numberOfParkingSpace);
    this.userForm.value.waterConsumption = this.userForm.controls['waterConsumption'].setValue(this.userForm.value.waterConsumption);

    let dataToSave = this.userForm.value;

    console.log(this.userForm.value.ProjectCostInUSD);
    // dataToSave.ProjectCostInUSD = this.utilService.returnToNumber(this.userForm.value.ProjectCostInUSD);
    // dataToSave.ProjectCostInRwf = this.utilService.returnToNumber(this.userForm.value.ProjectCostInRwf);
    // dataToSave.priceOfDwellingUnitRwf = this.utilService.returnToNumber(this.userForm.value.priceOfDwellingUnitRwf);

    dataToSave.ProjectCostInUSD = this.userForm.value.ProjectCostInUSD;
    dataToSave.ProjectCostInRwf = this.userForm.value.ProjectCostInRwf;
    dataToSave.priceOfDwellingUnitRwf = this.userForm.value.priceOfDwellingUnitRwf;

    dataToSave.buildUpArea = (+dataToSave.buildUpArea);
    dataToSave.numberOfFloor = (+dataToSave.numberOfFloor);
    dataToSave.waterConsumption = (+dataToSave.waterConsumption);
    dataToSave.electricityConsumption = (+dataToSave.electricityConsumption);


    dataToSave.combiningPlotSize = dataToSave.combiningPlotSize ? (+dataToSave.combiningPlotSize) : 0;
    dataToSave.isAssociated = dataToSave.combiningPlotSize ? true : false;

    dataToSave.numberOfParkingSpace = (+dataToSave.numberOfParkingSpace);
    dataToSave.capacityInformation = (+dataToSave.capacityInformation);

    dataToSave.other = this.returnOtherInfo();
    dataToSave.submittedByUserId = this.currentUser.userId;
    dataToSave.userId = this.applicationDetails.userId;

    if (this.formAccess.ApplicationAlreadyExists) {
      this.onNext();
    } else {
      this.applicationService.saveWithPath(dataToSave, environment.applicationUrl + 'application/application')
        .subscribe(
          data => {
            this.utilService.showNotification(NOTIFICATION_COLOR.success, "Your application saved successfully", "bottom", "center");
            // localStorage.setItem('application', JSON.stringify(data));
            this.userForm.controls['applicationId'].setValue(data?.id);
            this.onNext();
            this.submitted = false;
          }, error => {
            this.submitted = false;
          }
        )
    }

  }



  comeAndAllowToAssign(event: any) {
    console.log(event);
    if (event.found) {
      this.formAccess.isIAEverified = true;
      this.formAccess.isEIAVerified = event.isEIAVerified;
      this.formAccess.isRdbIAEchecked = true;
      this.formAccess.isRetreived = 1;
      this.userForm.controls['certificateNumberEIA'].setValue(event?.certNumber);
      this.userForm.controls['isEIAVerified'].setValue(event?.isEIAVerified);

    } else {
      this.formAccess.isIAEverified = false;
      this.formAccess.isEIAVerified = event.isEIAVerified;
      this.formAccess.isRdbIAEchecked = true;
      // this.formAccess.isRetreived = 0;
      this.formAccess.isRetreived = 1;
      this.userForm.controls['certificateNumberEIA'].setValue(event?.certNumber);
      this.userForm.controls['isEIAVerified'].setValue(event?.isEIAVerified);

    }
  }


  allowToAssign() {
    this.formAccess.doYouWantToShareToEngineer = !this.formAccess.doYouWantToShareToEngineer;
  }




  getUrlParamater() {
    if (!this.formAccess.ApplicationAlreadyExists) {
      return this.userForm.value.applicationId;
    } else {
      return this.userForm.value.applicationId;
      // return this.paramsId
    }
  }

  onPrev() {
    this.router.navigate(['/account/application/new-application-project/', this.params.projectId]);
  }



  formAccessData() {
    this.pageForm = this.formBuilder.group({
      doYouWantToDoItYouself: [false],
      doYouWantToAssignToEngineer: [false],
    })
  }


  refresh() {
    this.userForm = this.formBuilder.group({
      applicationId: [""],
      permitTypeId: ["", [Validators.required]],
      categoryTypeId: ["", [Validators.required]],
      buildTypeId: ["", [Validators.required]],
      permitTypeCode: [""],
      applicationStatusId: [""], // hard coded
      userId: [this.currentUser.userId],
      agencyId: [""],
      projectId: ["", [Validators.required]],
      agencyCode: ["", [Validators.required]],
      categoryCode: [""],
      usedPermitType: [""],
      certificateNumberEIA: [""],
      applicationStatusCode: [''],
      waterConsumption: ["", [Validators.required]],
      electricityConsumption: ["", [Validators.required]],
      DistanceToTheNearestLandIn: ["", [Validators.required]],
      ProjectCostInUSD: ["", [Validators.required]],
      ProjectCostInRwf: ["", [Validators.required]],
      buildUpArea: ["", [Validators.required]],
      numberOfFloor: ["", [Validators.required]],  // help to calculate amount
      grossFloorArea: ["", [Validators.required]],
      numberOfParkingSpace: [""],
      priceOfDwellingUnitRwf: ["", [Validators.required]],
      capacityInformation: ["", [Validators.required]],
      numberOfDwellingUnits: [""],
      DescriptionOfOperation: [""],
      plotSize: [0],
      percentageSpaceUse: ["", [Validators.required]],
      technologySurveyId: [""],
      upi: [""],
      combiningPlotSize: [""],
      isAssociated: [""],
    }, { validator: this.buildUpAreaValidator })
  }


  loadLookups() {
    this.applicationService.findAllWithPath(environment.authUrl + APIURLPATH.AGENCIES)
      .subscribe(
        data => {
          this.agencies = data;
        }
      )

    this.applicationService.findAllWithPath(environment.applicationUrl + APIURLPATH.TECHNOLOGYSURVEY)
      .subscribe(
        data => {
          this.technologySurveys = data;
          console.log(this.technologySurveys);
          this.userForm.controls['technologySurveyId'].setValue(this.technologySurveys[0].id);
        }
      )
    this.applicationService.findAllWithPath(environment.applicationUrl + APIURLPATH.BUILDING_TYPE)
      .subscribe(
        data => { this.buildingTypes = data; }
      )
    this.applicationService.findAllWithPath(environment.applicationUrl + APIURLPATH.CATEGORYTYPE)
      .subscribe(
        data => { this.categoryTypes = data; }
      )


    // this.applicationService.findAllWithPath(environment.applicationUrl + 'application/permitType/code/search?search=NCP')
    //   .subscribe(
    //     data => { this.permitTypes = data.items }
    //   )

    this.applicationService.findAllWithPath(environment.applicationUrl + APIURLPATH.PERMITYPES)
      .subscribe(
        data => { this.permitTypes = data; }
      )

  }




  returnOtherInfo() {
    return {
      "userId": "",
      "licenseNumber": "",
      "permitTypeId": "",
      "applicationId": "",
      "doYouHaveTheOccupancy": "",
      "isFastAidBox": "",
      "disabilityToiletsFlipUpGrabBars": "",
      "paraLighteningSystem": "",
      "equipmentCapacity": "",
      "constructionMethod": "",
      "fireAlarmSystemWithAnAlarmBellOnEach": "",
      "whyNotFireAlarmSystemWithAnAlarmBellOnEach": "",
      "fireExtinguishersEvery50mOnEachFloor": "",
      "whyNotFireExtinguishersEvery50mOnEachFloor": "",
      "functioningExitSignsOnEachFloor": "",
      "whyNotfunctioningExitSignsOnEachFloor": "",
      "anEmergencyExitOnEachFloor": "",
      "whyNotanEmergencyExitOnEachFloor": "",
      "floorPlanOnEachLevel": "",
      "whyNotfloorPlanOnEachLevel": "",
      "numberSignOnEachFloor": "",
      "whyNotnumberSignOnEachFloor": "",
      "signForbiddingTheUseOfElevatorsInCaseOfFire": "",
      "whyNotsignForbiddingTheUseOfElevatorsInCaseOfFire": "",
      "landingSpaceOnTopOfTheBuildingForHelicopters": "",
      "whyNotlandingSpaceOnTopOfTheBuildingForHelicopters": "",
      "CCTVCameras": "",
      "whyNotCCTVCameras": "",
      "WalkThroughAndHeldMetalDetect": "",
      "whyNotWalkThroughAndHeldMetalDetect": "",
      "UnderSearchMirror": "",
      "whyNotUnderSearchMirror": "",
      "LuggageScanners": "",
      "whyNotLuggageScanners": "",
      "PlatesIndicatingEmergencyResponseUnitsPhoneNumbers": "",
      "whyNotPlatesIndicatingEmergencyResponseUnitsPhoneNumbers": "",
      "EmergencyEvacuationPlan": "",
      "whyNotEmergencyEvacuationPlan": "",
      "SecurityManagerAndStaffCameras": "",
      "whyNotSecurityManagerAndStaffCameras": "",
      "AnInternalCommunicationSystem": "",
      "whyNotAnInternalCommunicationSystem": "",
      "BroadBandInternetServices": "",
      "whyNotBroadBandInternetServices": "",
      "StaffAndVisitorAccessCards": "",
      "whyNotStaffAndVisitorAccessCards": "",
      "applicationForFixedTelephoneLineConnection": "",
      "areThereAnyFacilitiesForTheDisabledProvidedBuilding": "",
      "whyNotAreThereAnyFacilitiesForTheDisabledProvidedBuilding": "",
      "stageOfConstruction": "",
      "supervisingFirmSiteEngineer": "",
      "remarks": "",
      "dateForRequestedInspection": ""
    }
  }

}
