import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";


@Injectable({
  providedIn: "root",
})
export class HeaderOptions {
  httpOptions: any;
  httpFormDataOptions: any;
  httpAuthorizedOptions: any;
  constructor(
  ) {
    this.httpOptions = {
      headers: new HttpHeaders(
        {
          'accept': '*/*',
          'Content-Type': 'application/json',
          // 'KUBAKA-API-KEY': '1ab2c3d4e5f61ab2c3d4e5f6'
        }
      )
    };


    this.httpFormDataOptions = {
      headers: new HttpHeaders(
        {
          "accept": "*/*",
          // "Content-Type": "multipart/form-data",
          // 'KUBAKA-API-KEY': '1ab2c3d4e5f61ab2c3d4e5f6'
        })
    }
  }



  setHeaderOptions() {
    this.httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json;', 'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        }
      )
    };


    this.httpFormDataOptions = {
      headers: new HttpHeaders(
        {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
          'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
          Pragma: 'no-cache',
          Expires: '0'
        })
    }
  }



}
