import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApplicationService } from '../../services/application.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NOTIFICATION_COLOR, UtilService } from 'src/app/shared/services/util.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-eia-form-checker',
  templateUrl: './eia-form-checker.component.html',
  styleUrls: ['./eia-form-checker.component.scss']
})
export class EiaFormCheckerComponent {
  @Input() inputData: any = {};
  @Output() backToParent = new EventEmitter();
  userForm!: UntypedFormGroup;
  submitted: boolean = false;
  currentUser: any = {};
  fileData: any = {};

  constructor(
    private applicationService: ApplicationService,
    private formBuilder: UntypedFormBuilder,
    private utilService: UtilService,
    private router: Router
  ) {
    // this.currentUser = this.sessionService.getSession();
    // this.currentUser.userId = (jwtDecode(this.currentUser.data.token.access_token) as any).UserId;
  }


  ngOnInit(): void {
    console.log(this.inputData);
    this.userForm = this.formBuilder.group({
      certificateNumber: ["", [Validators.required]],
      document: [""],
      upi: [this.inputData.upi],
      message: [""]
    });

    this.EIAChecker();
  }

  getFileDetails(e: any) {
    this.fileData = this.utilService.getFileDetails(e);
  }


  EIAChecker() {
    // if (this.fileData) {
    let formData = new FormData();
    // const fileList: FileList = this.fileData.file;
    // if (fileList && fileList[0]) {
    //   const file: File = fileList[0];
    //   this.fileData.fileNameDisplay = file.name
    //   formData.append('file', file, file.name);
    // }
    // formData.append('certificateNumber', this.userForm.value.certificateNumber);
    // if (this.userForm.value.certificateNumber) {  // && this.fileData.file
    //   this.submitted = true;

    //   let dataToSubmit = {
    //     "certificateNumber": this.userForm.value.certificateNumber
    //   }
    // this.applicationService.saveAssetWithPathFormData(dataToSubmit, environment.integrationUrl + '?certificateNumber=' + this.userForm.value.certificateNumber)
    // this.applicationService.saveAssetWithPath(dataToSubmit, environment.integrationUrl + 'integration/eia?certificateNumber=' + this.userForm.value.certificateNumber)
    this.userForm.controls['message'].setValue("");
    this.applicationService.saveAssetWithPath(JSON.stringify({}), environment.integrationUrl + 'integration/eia?upi=' + this.inputData.upi)
      .subscribe(
        data => {
          if (data.data && data.data.project_reference_number) {
            this.userForm.controls['certificateNumber'].setValue(data.data.project_reference_number);
            let backData = {
              certNumber: data.data.project_reference_number,
              isEIAVerified: true,
              found: true
            }
            this.backToParent.emit(backData);
          } else {
            let backData = {
              certNumber: "",
              isEIAVerified: false,
              found: false
            }
            this.backToParent.emit(backData);
            this.userForm.controls['message'].setValue("EIA is not valid you can not continue without the Environmental Impact Assessment");
            this.utilService.showNotification(NOTIFICATION_COLOR.error, `Data not found.
              Please submit your request to RDB for an Environmental Impact Access certificate`, "bottom", "center");
          }
          // if (data.data && data.data.document_status === 'Valid') {
          //   let backData = {
          //     certNumber : this.userForm.value.certificateNumber,
          //     found: true
          //   }
          //   this.backToParent.emit(backData);
          // } else {
          //   this.utilService.showNotification(NOTIFICATION_COLOR.error, `Data not found.
          //   Please submit your request to RDB for an Environmental Impact Access certificate`, "bottom", "center");
          // }
          this.submitted = false;
        }, error => {
          this.submitted = false;
          this.utilService.showNotification(NOTIFICATION_COLOR.error, `Data not found.
              Please submit your request to RDB for an Environmental Impact Access certificate`, "bottom", "center");
        }
      )
    // } else {
    //   this.toggleErrorMessage();
    // }
    // } else {
    //   this.submitted = false;
    //   this.utilService.showNotification(NOTIFICATION_COLOR.error, "File is not provided", "bottom", "center");
    // }

  }



  toggleErrorMessage() {
    if (!this.userForm.value.certificateNumber) {
      this.utilService.showNotification(NOTIFICATION_COLOR.error, "Fill certificate number please", "bottom", "center");
    }
    if (!this.fileData.file) {
      this.utilService.showNotification(NOTIFICATION_COLOR.error, "Please upload certificate ", "bottom", "center");
    }
  }


  openEIAclick() {
    const url = 'https://osc.rdb.rw/en/'
    window.open(url, '_blank');
  }

  // openEIAclick() {
  //   // href="https://osc.rdb.rw/en/"
  //   const url = this.router.serializeUrl(
  //     this.router.createUrlTree(['https://osc.rdb.rw/en/']),
  //   );
  //   window.open(url, '_blank');
  // }




}
