import { Component } from '@angular/core';
import {
  CellValueChangedEvent,
  ColDef,
  GridReadyEvent,
  ICellRendererParams,
  SelectionChangedEvent,
  ValueFormatterParams,
} from 'ag-grid-community';
import { AgGridAngular, ICellRendererAngularComp } from 'ag-grid-angular';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


export class MissionResultRenderer implements ICellRendererAngularComp {
  // Init Cell Value
  public value!: string;
  agInit(params: ICellRendererParams): void {
    this.value = params.value ? 'tick-in-circle' : 'cross-in-circle';
  }

  // Return Cell Value
  refresh(params: ICellRendererParams): boolean {
    this.value = params.value;
    return true;
  }
}

export class CompanyLogoRenderer implements ICellRendererAngularComp {
  // Init Cell Value
  public value!: string;
  agInit(params: ICellRendererParams): void {
    this.value = params.value;
  }

  // Return Cell Value
  refresh(params: ICellRendererParams): boolean {
    this.value = params.value;
    return true;
  }
}

@Component({
  selector: 'app-certificate-report',
  templateUrl: './certificate-report.component.html',
  styleUrls: ['./certificate-report.component.scss']
})
export class CertificateReportComponent {


  // Return formatted date value
  dateFormatter(params: ValueFormatterParams) {
    return new Date(params.value).toLocaleDateString('en-us', {
      weekday: 'long',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }

  // Row Data: The data to be displayed.
  rowData: any[] = [];

  // Column Definitions: Defines & controls grid columns.
  colDefs: ColDef[] = [
    {
      field: 'firstName',
      width: 150,
      checkboxSelection: true,
    },
    {
      field: 'lastName',
      width: 130,
      // cellRenderer: CompanyLogoRenderer,
    },
    {
      field: 'gender',
      width: 130,
      // cellRenderer: CompanyLogoRenderer,
    },
    
    {
      field: 'email',
      width: 225,
    },
    {
      field: 'createdAt',
      valueFormatter: this.dateFormatter,
    },
    {
      field: 'role.name',
      width: 130,
      // valueFormatter: (params) => {
      //   return '£' + params.value.toLocaleString();
      // },
    },
    {
      field: 'userType.name',
      width: 120,
      // cellRenderer: MissionResultRenderer,
    },
    { field: 'agency.name' },
  ];

  // Default Column Definitions: Apply configuration across all columns
  defaultColDef: ColDef = {
    filter: true, // Enable filtering on all columns
    editable: true, // Enable editing on all columns
  };

  // Load data into grid when ready
  constructor(private http: HttpClient) { }
  onGridReady(params: GridReadyEvent) {
    this.http
      .get<any[]>(
        environment.authUrl + 'user-management/users'
      )
      .subscribe((data) => (this.rowData = data));
  }

  // Handle row selection changed event
  onSelectionChanged = (event: SelectionChangedEvent) => {
    console.log('Row Selected!');
  };

  // Handle cell editing event
  onCellValueChanged = (event: CellValueChangedEvent) => {
    console.log(`New Cell Value: ${event.value}`);
  };
}
