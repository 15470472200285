import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appCurrencyFormatter]'
})
export class CurrencyFormatterDirective {

  private lastValue: string | null = null;

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const input = this.el.nativeElement;
    let value = input.value.replace(/[^0-9.]/g, '');  // Strip out any non-numeric characters

    // Format the value as currency
    const formattedValue = this.formatCurrency(value);

    if (this.lastValue !== formattedValue) {
      input.value = formattedValue;
      this.lastValue = formattedValue;
    }
  }

  formatCurrency(value: string): string {
    if (!value) {
      return '';
    }

    const numberValue = parseFloat(value);

    if (isNaN(numberValue)) {
      return '';
    }

    // Format the number as currency using the built-in `toLocaleString` method
    return numberValue.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }
}


@Directive({
  selector: '[noNegative]'
})
export class NoNegativeDirective {
  constructor(private ngControl: NgControl) {}

  @HostListener('input', ['$event'])
  onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    // Remove any characters that are not digits or a decimal point
    value = value.replace(/[^0-9.]/g, '');

    // Ensure only one decimal point is allowed
    const decimalIndex = value.indexOf('.');
    if (decimalIndex !== -1) {
      value = value.substring(0, decimalIndex + 1) + value.substring(decimalIndex + 1).replace(/\./g, '');
    }

    // Update the input value in the control
    this.ngControl.control?.setValue(value);
  }
}


@Directive({
  selector: '[appCommaSeparator]'
})
export class CommaSeparatorDirective {

  private lastValue: string | null = null;

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const input = this.el.nativeElement;
    let value = input.value.replace(/,/g, '');  // Remove any existing commas

    // Only process the value if it's a valid number
    if (!isNaN(Number(value)) && value !== '') {
      // Format the number with commas
      const formattedValue = this.formatNumber(value);

      if (this.lastValue !== formattedValue) {
        input.value = formattedValue;
        this.lastValue = formattedValue;
      }
    } else {
      input.value = '';  // Clear input if the value is not a valid number
    }
  }

  formatNumber(value: string): string {
    // Convert the value to a string, add commas
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }




  
}