import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { TokenService } from 'src/app/auth-pages/services/token.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private tokenService: TokenService,
    ) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser: any = this.tokenService.getToken();
        if (currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: {
                    'accept': '*/*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${currentUser.token}`,
                },
            });
        }
        return next.handle(request);
    }
}
