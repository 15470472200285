import { Component, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
// import { Crisp } from "crisp-sdk-web";
import { AppConfig } from './app.config';
import { NOTIFICATION_COLOR, UtilService } from './shared/services/util.service';
import { IdleService } from './shared/services/idle.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    idleService = inject(IdleService)
    private idleSubscription?: Subscription;
    upiFinder = '';
    showChat: boolean = false;
    toggleIcon: string = 'open'; // Initially set to open icon
    toggleChat() {
        this.showChat = !this.showChat;
        this.toggleIcon = this.showChat ? 'close' : 'open'; // Change the icon
        console.log(this.showChat);
    }

    constructor(
        private translateService: TranslateService,
        private utilService: UtilService,
        private appConfig: AppConfig,
        private router: Router) {
        // const userLang = navigator.language || 'en';
        // const languageCode = userLang.split('-')[0];
        // this.translateService.setDefaultLang(languageCode);
        // this.translateService.use(languageCode);

        translateService.addLangs(['en', 'kin', 'fr']);
        translateService.setDefaultLang('en');

        // this.utilService.showNotification(NOTIFICATION_COLOR.error, "Invalid username or password", "bottom", "center");


        // Crisp.configure("b8fb124c-627f-474c-a457-6624926dc4e1");
    }


    ngOnInit(): void {
        this.idleSubscription = this.idleService.idleState.subscribe((isIdle) => {
            if (isIdle) {
                // console.log('User is idle');
                this.logout();
            } else {
                // console.log('User is active');
            }
        });
    }

    logout() {
        localStorage.removeItem(this.appConfig.JWT_Token);
        localStorage.removeItem(this.appConfig.Session_User);
        localStorage.removeItem(this.appConfig.UPI_NEW_INFO);
        localStorage.removeItem(this.appConfig.UPI_old_INFO);
        this.router.navigate(['']);
        // this.router.navigate(['/auth/login']);
    }

    ngOnDestroy(): void {
        if (this.idleSubscription) {
            this.idleSubscription.unsubscribe();
        }
        // throw new Error('Method not implemented.');
    }


    onUserAction() {
        this.idleService.resetTimer();
    }

}