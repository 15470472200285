import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth-pages/services/auth.service';

@Component({
  selector: 'app-one-field-form',
  templateUrl: './one-field-form.component.html',
  styleUrls: ['./one-field-form.component.scss']
})
export class OneFieldFormComponent {
  @Output() backToParent = new EventEmitter();
  submitted = false;
  @Input() inputData: any = {};
  fieldForm!: UntypedFormGroup;
  booleanForm: any = {};


  constructor(
    private userService: AuthService,
    private formBuilder: UntypedFormBuilder,

  ) {

  }

  ngOnInit(): void {
    this.fieldForm = this.inputData.userForm;

    if (this.inputData.type === 'edit') {
      // var modelTitle = document.querySelector(".modal-title") as HTMLAreaElement;
      // modelTitle.innerHTML = "Edit User";
      // var updateBtn = document.getElementById("add-btn") as HTMLAreaElement;
      // updateBtn.innerHTML = "Update";
    }
  }



  get form() {
    return this.fieldForm.controls;
  }

  saveUser() {
    if (this.fieldForm.invalid) {
    } else {
      if (this.fieldForm.get("id")?.value) {
        this.submitted = true;
        this.userService.patchAssetWithPath(this.fieldForm.value.id, this.fieldForm.value, this.inputData.url)
          .subscribe(
            (data: any) => {
              this.backToParent.emit({ message: 'Record updated successfully!', status: true });
              this.submitted = false;
            },)
      } else {
        let dataToSubmit = {
          name: this.fieldForm.value.name,
          code: this.fieldForm.value.code
        }
        this.userService.saveAssetWithPath(dataToSubmit, this.inputData.url)
          .subscribe(
            (data: any) => {
              this.backToParent.emit({ message: 'Record inserted successfully!', status: true });
            },
          )
      }
      // setTimeout(() => {
      //   // this.userForm.reset();
      // }, 2000);
      // this.submitted = true;
    }
  }

}
