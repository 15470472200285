export const APIURLPATH = {
    ROLE: 'user-management/roles',
    USERS: 'user-management/users',
    USERACTIVITIES: 'user-management/userActivities',
    USERTYPE: 'user-management/userType',
    CIVILSTATUS: 'user-management/civilStatus',
    PERMISSIONS: 'user-management/permissions',
    COUNTRIES: 'user-management/countries',
    PROVINCES: 'user-management/provices',
    DISTRICTS: 'user-management/districts',
    SECTORS: 'user-management/sectors',
    CELLS: 'user-management/cells',
    VILLAGES: 'user-management/villages',
    AGENCIES: 'user-management/agencies',
    AGENCY_DISTRICTS: 'user-management/agencyDistrict',
    TECHNOLOGYSURVEY: 'application/technologySurvey',
    CATEGORYTYPE: 'application/categoryType',
    PERMITYPES: 'application/permitType',
    QUESTION_CATEGORIES: 'application/questionCategory',
    BUILDING_TYPE: 'application/buildType',
    DOCUMENTS_REQUIRED: 'application/requiredDocument',
    APPROVAL_LEVEL: 'approval/approvalLevel',
    APPROVAL_STATUS: 'approval/approvalStatus',
    CHECKLIST: 'approval/permitCheckList',

     PERMIT_QUESTION: 'application/permitQuestion',
    INVOICE_ITEM: 'invoice/invoiceItem',
    DOCUMENT_TYPE: 'application/documentType',
    PERMIT_TYPE_QUESTIONS: 'application/permitQuestion',
    
}