import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-view-document',
  templateUrl: './view-document.component.html',
  styleUrls: ['./view-document.component.scss']
})
export class ViewDocumentComponent {

  @Input() inputData: any;
  safeDocumentUrl: SafeResourceUrl = {};
  documentSrc: any = {};

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    console.log(this.inputData);
    this.getPhoto();
  }



  getPhoto() {
    // const linkSource = 'data:application/pdf;base64,' + this.inputData.document;
    const decodedPdf = atob(this.inputData.base64Data);
    const byteArray = new Uint8Array(decodedPdf.length);
    for (let i = 0; i < decodedPdf.length; i++) {
      byteArray[i] = decodedPdf.charCodeAt(i);
    }
    const blob = new Blob([byteArray.buffer], { type: 'application/pdf' });
    const _pdfurl = URL.createObjectURL(blob);
    this.documentSrc = this.sanitizer.bypassSecurityTrustResourceUrl(_pdfurl);;
  }

}
