import { Component, EventEmitter, Output } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { interval, Subscription, takeWhile } from 'rxjs';
import { ApplicationService } from 'src/app/application/services/application.service';
import { SessionService } from 'src/app/authentication-services/session.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-header-notification',
  templateUrl: './header-notification.component.html',
  styleUrls: ['./header-notification.component.scss']
})
export class HeaderNotificationComponent {
  private isDestroyed = false;
  @Output() backToParent = new EventEmitter();
  private subscription!: Subscription;
  notificationNumber: any = {};
  currentUser: any = {};


  constructor(
    private applicationService: ApplicationService,
    private sessionService: SessionService,

  ) {
    if (this.sessionService.getSession() && this.sessionService.getSession().data && this.sessionService.getSession().data.user) {
      this.currentUser = this.sessionService.getSession();
      this.currentUser.userId = (jwtDecode(this.currentUser.data.token.access_token) as any).UserId;
    }
  }

  ngOnInit() {
    this.startCheckingBoolean();
  }

  startCheckingBoolean() {
    this.subscription = interval(7000).subscribe(() => {
      if (!this.isDestroyed) {
        this.callHeaderNumber();
      }
    });
  }


  ngOnDestroy() {
    this.isDestroyed = true;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }



  callHeaderNumber() {
    this.applicationService.findAllWithPath(environment.applicationUrl + 'invoice/invoiceStatus/code/search?search=PND')
      .subscribe(
        data => {
          if (data && data.items) {
            this.applicationService.findAllWithPath(environment.applicationUrl + 'invoice/invoice/applicant/'
              + this.currentUser.userId + '/invoiceStatus/' + data.items[0]?.id)
              .subscribe(
                data => {
                  if (data.number > 0) {
                    this.notificationNumber.isInvoicePending = true;
                    this.backToParent.emit(this.notificationNumber);
                  } else {
                    this.notificationNumber.isInvoicePending = false;
                    this.backToParent.emit(this.notificationNumber);
                  }

                },
              )
          }

        },
      )

    this.applicationService.findAllWithPath(environment.chatUrl + 'getUnreadMessage/' + this.currentUser.userId)
      .subscribe(
        data => {
          if (data.number > 0) {
            this.notificationNumber.chatNumber = data.number;
            this.backToParent.emit(this.notificationNumber);
          } else {
            this.notificationNumber.chatNumber = 0;
            this.backToParent.emit(this.notificationNumber);
          }
        },
      )


    if (this.currentUser.data.user.role.code === "DRCT") {
      this.applicationService.findAllWithPath(environment.applicationUrl + 'application/application/dashboard/Agency/' +
        this.currentUser.data.user.agency.id + '/role/' + 'DRCT')
        .subscribe(
          data => {
            this.notificationNumber.myBoxNumber = data.number;
            this.backToParent.emit(this.notificationNumber);
          },
        )
    } else if (this.currentUser.data.user.role.code === 'TMLD') {
      this.applicationService.findAllWithPath(environment.applicationUrl + 'application/application/dashboard/Agency/'
        + this.currentUser.data.user.agency.id + '/role/' + 'TMLD')
        .subscribe(
          data => {
            this.notificationNumber.myBoxNumber = data.number;
            this.backToParent.emit(this.notificationNumber);
          },
        )
    } else if (this.currentUser.data.user.role.code === 'OFCMNG') {
      this.applicationService.findAllWithPath(environment.applicationUrl + 'application/application/dashboard/Agency/'
        + this.currentUser.data.user.agency.id + '/role/' + 'OFCMNG')
        .subscribe(
          data => {
            this.notificationNumber.myBoxNumber = data.number;
            this.backToParent.emit(this.notificationNumber);
          },

        )
    } else if (this.currentUser.data.user.role.code === 'STF') {
      console.log('Please select');
      this.applicationService.findAllWithPath(environment.applicationUrl + 'application/reviewersOnApplication/myBox/' + this.currentUser.userId)
        .subscribe(
          data => {
            this.notificationNumber.myBoxNumber = data.number;
            this.backToParent.emit(this.notificationNumber);
          },
        )
    }
  }

}
