<div class="kbk-table">
    <div class="kbk-table_hder p-cont">
        <span class="hder" aria-label="header tittle">{{outPutData.name}}s</span>
        <div class="btns sp-xs">
            <button type="button" class="kbk-btn kbk-btn-main" data-bs-toggle="modal" id="create-btn"
                data-bs-target="#showModal" (click)="openModal(content)">Create {{outPutData.name}}</button>
            <!-- <button type="button" class="kbk-btn kbk-btn-outline" (click)="csvFileExport()">Export</button> -->
        </div>
    </div>
    <div class="kbk-table_body">
        <!-- Nav tabs -->
        <ul ngbNav #nav="ngbNav" [activeId]="1" class="kbk-tabs">
            <li [ngbNavItem]="1" class="kbk-tabs-item">
                <ng-template ngbNavContent>
                    <div class="kbk-table_flt">
                        <div class="form-input">
                            <div class="form-input_search fsm">
                                <input type="text" name="searchTerm" placeholder="Search for something..."
                                    [(ngModel)]="userService.searchTerm">
                                <button type="button" class="btn">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <g data-name="Layer 2">
                                            <g data-name="search">
                                                <rect width="24" height="24" opacity="0" />
                                                <path
                                                    d="M20.71 19.29l-3.4-3.39A7.92 7.92 0 0 0 19 11a8 8 0 1 0-8 8 7.92 7.92 0 0 0 4.9-1.69l3.39 3.4a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42zM5 11a6 6 0 1 1 6 6 6 6 0 0 1-6-6z" />
                                            </g>
                                        </g>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="kbk-table-responsive">
                        <table>
                            <thead>
                                <tr>
                                    <th scope="col" style="width: 25px;">
                                        <label class="form-checkbox form-checkbox-dft">
                                            <input type="checkbox" id="checkAll" value="option"
                                                [(ngModel)]="masterSelected" (change)="checkUncheckAll($event)">
                                        </label>
                                    </th>
                                    <th data-sort="name">Name</th>
                                    <th data-sort="code">Code </th>
                                    <th data-sort="description">Description</th>
                                    <th data-sort="">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of lists| genericfilter: userService.searchTerm"
                                    id="o_{{data._id}}">
                                    <td scope="row">
                                        <label class="form-checkbox form-checkbox-dft">
                                            <input type="checkbox" name="checkAll" value="{{data._id}}"
                                                [(ngModel)]="data.state" (change)="onCheckboxChange($event)">
                                        </label>
                                    </td>
                                    <td>
                                        <ngb-highlight [result]="data.name" [term]="userService.searchTerm">
                                        </ngb-highlight>
                                    </td>
                                    <td>
                                        <ngb-highlight [result]="data.code" [term]="userService.searchTerm">
                                        </ngb-highlight>
                                    </td>

                                    <td>
                                        <ngb-highlight [result]="data.description" [term]="userService.searchTerm">
                                        </ngb-highlight>
                                    </td>
                                    <td>
                                        <ul class="list-inline hstack gap-2 mb-0">
                                            <li class="list-inline-item edit" data-bs-toggle="tooltip"
                                                data-bs-trigger="hover" ngbTooltip="Edit" placement="top">
                                                <a href="javascript:void(0);" data-bs-toggle="modal"
                                                    class="text-primary d-inline-block edit-item-btn"
                                                    data-bs-toggle="modal" id="create-btn" data-bs-target="#showModal"
                                                    (click)="editDataGet(data.id,content)">
                                                    <i class="ri-pencil-fill fs-16"></i>
                                                </a>
                                            </li>
                                        </ul>
                                        <div class="kbk-table-dropdown">
                                            <button class="kbk-link-btn">More</button>
                                            <div class="kbk-table-dropdown_list">
                                                <a class="kbk-btn kbk-btn-sec kbk-btn-sm"
                                                data-bs-toggle="modal"
                                                    class="text-primary d-inline-block edit-item-btn"
                                                    data-bs-toggle="modal" id="create-btn" data-bs-target="#showModal"
                                                    (click)="editDataGet(data.id,content)">Edit</a>
                                                <!-- <a class="kbk-btn kbk-btn-sec kbk-btn-sm">Delete</a> -->
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="pagnation">
                        <div class="pagnation-item">
                            <div class="pagnation-dt" id="tickets-table_info" role="status" aria-live="polite">
                                <span class="ent">{{totalRecords}}</span> <span class="cur">{{startIndex}} -
                                    {{endIndex}}</span>
                            </div>
                        </div>
                        <div class="pagnation-item">
                            <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="pageSize"
                                (pageChange)="getPremiumData();">
                            </ngb-pagination>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <!-- Tab panes -->
        <div class="tab-content text-muted">
            <div [ngbNavOutlet]="nav"></div>
            <div id="elmLoader">
                <div class="load-cont" role="status">
                    <div class="preload">
                        <div class="line-cont">
                            <span class="line1"></span>
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>
<!-- Create Model -->
<ng-template #content role="document" let-modal>
    <div class="modol-header">
        <h2 id="exampleModalLabel">{{this.userForm.value.id ? 'Edit' : 'New'}} {{outPutData.name}}</h2>
        <p>Create new {{outPutData.name}}</p>
        <button type="button" class="kbk-btn kbk-btn-sec kbk-btn-close" data-bs-dismiss="modal" aria-label="Close"
            id="close-modal" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modol-content">
        <form (ngSubmit)="saveValue()" [formGroup]="userForm" autocomplete="off">
            <input type="hidden" name="id" value="" formControlName="id" />
            <div class="form-set">
                <div class="form-input">
                    <label>Name</label>
                    <div>
                        <input type="text" id="name" name="name" formControlName="name" required
                        [ngClass]="{ 'is-invalid': submitted && form['name'].errors }">
                        <div *ngIf="submitted && form['name'].errors" class="invalid-feedback" align="left">
                            <div *ngIf="form['name'].errors['required']">Name is required</div>
                        </div>
                    </div>
                </div>
                <div class="form-input">
                    <label>Code</label>
                    <div>
                        <input type="text" id="code" name="code" formControlName="code" required
                        [ngClass]="{ 'is-invalid': submitted && form['code'].errors }">
                        <div *ngIf="submitted && form['code'].errors" class="invalid-feedback" align="left">
                            <div *ngIf="form['code'].errors['required']">Code is required</div>
                        </div>
                    </div>
                </div>
                <div class="form-input">
                    <label>Description</label>
                    <div>
                        <input type="text" id="description" name="description" formControlName="description" required
                        [ngClass]="{ 'is-invalid': submitted && form['description'].errors }">
                        <div *ngIf="submitted && form['description'].errors" class="invalid-feedback" align="left">
                            <div *ngIf="form['description'].errors['required']">description is required</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="kbk-x-c sp-sm mt-md">
                <button type="button" class="kbk-btn kbk-btn-sec">Cancel</button>
                <button *ngIf="submitted" type="button" class="kbk-btn kbk-btn-sec">Wait...</button>
                <button *ngIf="!submitted" type="submit"
                [disabled]="userForm.invalid" class="kbk-btn kbk-btn-main">{{this.userForm.value.id ? 'Update' : 'Save'}}</button>
            </div>
        </form>
    </div>
    <!-- <app-one-field-form *ngIf="outPutData.isOpen" [inputData]="outPutData"
        (backToParent)="saveValue($event)"></app-one-field-form> -->
</ng-template>
<!--End Modal -->
<ng-template #deleteModel let-modal>
    <div class="modal-content">
        <div class="modol-header">
            <h2 id="exampleModalLabel">You are about to delete a {{outPutData.name}} ?</h2>
            <p>Deleting your {{outPutData.name}} will remove all of your information from our database.</p>
            <button type="button" class="kbk-btn kbk-btn-sec kbk-btn-close" data-bs-dismiss="modal" aria-label="Close"
                id="btn-close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="mt-md">
                <h4>You are about to delete a {{outPutData.name}} ?</h4>
            </div>
            <div class="kbk-x-c sp-sm mt-md">
                <button type="button" class="kbk-btn kbk-btn-sec" data-bs-dismiss="modal"
                    (click)="modal.close('Close click')" id="deleteRecord-close">Close</button>
                <button type="button" class="kbk-btn kbk-btn-error" id="delete-product" (click)="deleteData('')"
                    (click)="modal.close('Close click')">Yes, Delete!</button>
            </div>
        </div>
    </div>
</ng-template>