import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/core/generic-service/crud-service';
import { HeaderOptions } from 'src/app/core/generic-service/header-options/header-options';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService extends CrudService<any, any> {

  constructor(
    protected httpClient: HttpClient,
    protected _headerOption: HeaderOptions,
  ) {
    super(httpClient, '', _headerOption);


  
  }
}
