import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { ApplicationService } from 'src/app/application/services/application.service';
import { SessionService } from 'src/app/authentication-services/session.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-foundation-inspection',
  templateUrl: './foundation-inspection.component.html',
  styleUrls: ['./foundation-inspection.component.scss']
})
export class FoundationInspectionComponent {
  @Input() inputData: any;
  @Output() backToParent = new EventEmitter();
  inspectionForm!: FormGroup;
  projectDetails: any = {};
  paramsId: any = '';
  page = 1;
  pageSize = 10;
  startIndex = 0;
  endIndex = 9;
  totalRecords = 0;
  collectionSize = 0;
  content?: any;
  total!: number;
  fullList: any[] = [];
  filteredList: any[] = [];
  lists: any[] = [];
  isNewInspection: boolean = false;
  currentUser: any;
  fileData: any = {};
  
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public applicationService: ApplicationService,
    private sessionService: SessionService,
  ) {
    this.currentUser = this.sessionService.getSession();
    this.currentUser.userId = (jwtDecode(this.currentUser.data.token.access_token) as any).UserId;

    // route.params.subscribe((params: any) => {
    //   this.paramsId = params.id;
    // });



  }

  getFileToSave(event: any) {
    this.fileData = event;
  }

  ngOnDestroy() {
    this.applicationService.searchTerm = '';
  }

  ngOnInit(): void {
    this.inspectionForm = this.fb.group({
      geotechnicalReportRespected: ['', Validators.required],
      foundationType: ['', Validators.required],
      proceduresComply: ['', Validators.required],
      soilTreatmentComply: ['', Validators.required],
      erosionControlMeasures: ['', Validators.required],
      footingSizeComply: ['', Validators.required],
      locationComply: ['', Validators.required],
      reinforcementSizeComply: ['', Validators.required],
      spacingReinforcementsComply: ['', Validators.required],
      concretePlacementComply: ['', Validators.required],
      plumbingComply: ['', Validators.required],
      comments: [''],
      applicationId: [this.inputData.applicationDetail.id, Validators.required],
      userId: [this.currentUser.userId, Validators.required]
    });

    this.loadList();

    // this.applicationService.findAllWithPath(environment.applicationUrl + 'application/permitType/code/search?search=NCP')
    //   .subscribe(

    //     data => {
    //       this.applicationService.findAllWithPath(environment.applicationUrl + 'application/application/' + this.paramsId + '/permit-type/' +
    //         data.items[0].id
    //       ).subscribe(
    //         dataItem => {
    //           this.projectDetails = dataItem[0];
    //           console.log(this.projectDetails);
    //         }
    //       )
    //     }

    //   )
  }

  loadList() {
    this.applicationService.findAllWithPath(environment.applicationUrl + 'application/inspection/foundation/ByApplication/' + this.inputData.applicationDetail.id
    ).subscribe(
      dataItem => {
        this.lists = dataItem;
      }
    )
  }


  getPremiumData() {
    this.filterAndPaginate();
  }

  filterAndPaginate() {
    const filtered = this.fullList.filter(item => this.applyFilter(item));
    this.filteredList = filtered;
    this.totalRecords = filtered.length;
    this.startIndex = (this.page - 1) * this.pageSize + 1;
    this.endIndex = (this.page - 1) * this.pageSize + this.pageSize;
    if (this.endIndex > this.totalRecords) {
      this.endIndex = this.totalRecords;
    }
    this.lists = filtered.slice(this.startIndex - 1, this.endIndex);
  }

  applyFilter(item: any): boolean {
    if (!this.applicationService.searchTerm) return true;
    const term = this.applicationService.searchTerm.toLowerCase();
    return Object.values(item).some(val =>
      String(val).toLowerCase().includes(term)
    );
  }


  onSearchTermChange(term: string): void {
    this.applicationService.searchTerm = term;
    this.filterAndPaginate();
  }

  cancel() {
    this.isNewInspection = !this.isNewInspection;
  }

  newInspection(event: any) {
    this.cancel()
    if (event) {

    }
  }

  onSubmit() {
    this.applicationService.saveAssetWithPath(this.inspectionForm.value, environment.applicationUrl + 'application/inspection/foundation')
      .subscribe(
        data => {
          this.cancel();
          this.loadList();
        }, error => { })
  }

  closeList() {
    this.backToParent.emit();
  }
}
