<div class="stepper-content">
    <div class="stepper-body">
        <div>
            <div class="step-panel">
                <div class="step-panel_header">
                    <div class="form-header">
                        <h2 class="display-md">Change password</h2>
                    </div>
                </div>
                <form class="form" [formGroup]="saveForm" (ngSubmit)="onSubmit()" autocomplete="off">
                    <div class="step-panel_body">
                        <div class="form-set">
                            <div class="form-input">
                                <label>Current Password</label>
                                <div>
                                    <input class="flg" type="password" id="currentPassword" name="currentPassword"
                                        formControlName="currentPassword" placeholder="**********" required />
                                </div>
                            </div>
                            <div class="form-input">
                                <label>New Password</label>
                                <div>
                                    <input class="flg" type="password" name="newPassword" formControlName="newPassword"
                                        placeholder="**********" required />
                                    <div *ngIf="
              saveForm.get('newPassword')?.invalid &&
              saveForm.get('newPassword')?.touched
            ">
                                        <small *ngIf="
                saveForm.get('newPassword')?.hasError('required')
              " class="text-danger">Password is required</small>
                                        <small *ngIf="
                saveForm.get('newPassword')?.hasError('minlength')
              " class="text-danger">Password must be at least 8 characters</small>
                                        <small *ngIf="
                saveForm.get('newPassword')?.hasError('pattern')
              " class="text-danger"> Password must contain at least one lowercase letter, one uppercase letter, and one
                                            digit </small>
                                    </div>
                                    <div *ngIf="
              saveForm.controls['newPassword'].hasError('pattern')
            ">
                                        <span class="text-danger">Password Must contain below conditions.</span>
                                        <ul>
                                            <li [ngClass]="{
                  'text-success': utilService.checkValidations(
                    saveForm.controls['newPassword'],
                    'uppercase'
                  ),
                  'text-danger': !utilService.checkValidations(
                    saveForm.controls['newPassword'],
                    'uppercase'
                  )
                }"> Min 1 Uppercase Letter. </li>
                                            <li [ngClass]="{
                  'text-success': utilService.checkValidations(
                    saveForm.controls['newPassword'],
                    'lowercase'
                  ),
                  'text-danger': !utilService.checkValidations(
                    saveForm.controls['newPassword'],
                    'lowercase'
                  )
                }"> Min 1 Lowercase Letter. </li>
                                            <li [ngClass]="{
                  'text-success': utilService.checkValidations(
                    saveForm.controls['newPassword'],
                    'special-character'
                  ),
                  'text-danger': !utilService.checkValidations(
                    saveForm.controls['newPassword'],
                    'special-character'
                  )
                }"> Min 1 Special Character. </li>
                                            <li [ngClass]="{
                  'text-success': utilService.checkValidations(
                    saveForm.controls['newPassword'],
                    'number'
                  ),
                  'text-danger': !utilService.checkValidations(
                    saveForm.controls['newPassword'],
                    'number'
                  )
                }"> Min 1 Number. </li>
                                            <li [ngClass]="{
                  'text-success': utilService.checkValidations(
                    saveForm.controls['newPassword'],
                    'length'
                  ),
                  'text-danger': !utilService.checkValidations(
                    saveForm.controls['newPassword'],
                    'length'
                  )
                }"> Min 8 Characters. </li>
                                            <li [ngClass]="{
                  'text-success': utilService.checkValidations(
                    saveForm.controls['newPassword'],
                    'length'
                  ),
                  'text-danger': !utilService.checkValidations(
                    saveForm.controls['newPassword'],
                    'length'
                  )
                }"> Max 16 Characters. </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="form-input">
                                <label>Confirm Password</label>
                                <div>
                                    <input class="flg" type="password" name="confirmPassword"
                                        formControlName="confirmPassword" placeholder="**********" required />
                                    <div *ngIf="
              saveForm.get('confirmPassword')?.invalid &&
              saveForm.get('confirmPassword')?.touched
            ">
                                        <small *ngIf="
                saveForm.get('confirmPassword')?.hasError('required')
              " class="text-danger">Password is required</small>
                                        <small *ngIf="
                saveForm.get('confirmPassword')?.hasError('minlength')
              " class="text-danger">Password must be at least 8 characters</small>
                                        <small *ngIf="
                saveForm.get('confirmPassword')?.hasError('pattern')
              " class="text-danger"> Password must contain at least one lowercase letter, one uppercase letter, and one
                                            digit </small>
                                    </div>
                                    <div *ngIf="
              saveForm.controls['confirmPassword'].hasError('pattern')
            ">
                                        <span class="text-danger">Password Must contain below conditions.</span>
                                        <ul>
                                            <li [ngClass]="{
                  'text-success': utilService.checkValidations(
                    saveForm.controls['confirmPassword'],
                    'uppercase'
                  ),
                  'text-danger': !utilService.checkValidations(
                    saveForm.controls['confirmPassword'],
                    'uppercase'
                  )
                }"> Min 1 Uppercase Letter. </li>
                                            <li [ngClass]="{
                  'text-success': utilService.checkValidations(
                    saveForm.controls['confirmPassword'],
                    'lowercase'
                  ),
                  'text-danger': !utilService.checkValidations(
                    saveForm.controls['confirmPassword'],
                    'lowercase'
                  )
                }"> Min 1 Lowercase Letter. </li>
                                            <li [ngClass]="{
                  'text-success': utilService.checkValidations(
                    saveForm.controls['confirmPassword'],
                    'special-character'
                  ),
                  'text-danger': !utilService.checkValidations(
                    saveForm.controls['confirmPassword'],
                    'special-character'
                  )
                }"> Min 1 Special Character. </li>
                                            <li [ngClass]="{
                  'text-success': utilService.checkValidations(
                    saveForm.controls['confirmPassword'],
                    'number'
                  ),
                  'text-danger': !utilService.checkValidations(
                    saveForm.controls['confirmPassword'],
                    'number'
                  )
                }"> Min 1 Number. </li>
                                            <li [ngClass]="{
                  'text-success': utilService.checkValidations(
                    saveForm.controls['confirmPassword'],
                    'length'
                  ),
                  'text-danger': !utilService.checkValidations(
                    saveForm.controls['confirmPassword'],
                    'length'
                  )
                }"> Min 8 Characters. </li>
                                            <li [ngClass]="{
                  'text-success': utilService.checkValidations(
                    saveForm.controls['confirmPassword'],
                    'length'
                  ),
                  'text-danger': !utilService.checkValidations(
                    saveForm.controls['confirmPassword'],
                    'length'
                  )
                }"> Max 16 Characters. </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1" *ngIf="saveForm.hasError('notMatched')">
                        <mat-error *ngIf="saveForm.hasError('notMatched')">
                            <span class="danger">Passwords are not matched.</span>
                        </mat-error>
                    </div>
                    <div class="step-panel_footer">
                        <button class="kbk-btn kbk-btn-main" type="submit" [disabled]="saveForm.invalid">Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>