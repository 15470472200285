import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormattingService {

  constructor() { }



  plannedLandUseFormat(arrayList: any) {
    return arrayList.map((element: any) => {
      let lndUsen = element.landUseName.split('-');
      element.header = 'Zoning Regulation';
      element.landUse = lndUsen[0];
      element.use = lndUsen[1];
      element.area = element.area
      return element;
    });
  }
}
