import { Component } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/authentication-services/session.service';
import { NOTIFICATION_COLOR, UtilService } from 'src/app/shared/services/util.service';
import { UserMgtService } from 'src/app/user-management/services/user-mgt.service';
import { environment } from 'src/environments/environment';


// export class MyErrorStateMatcher implements ErrorStateMatcher {
//   isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
//     return !!(control && control.valid && (control.dirty || control.touched) && form?.hasError('notMatched'));
//   }
// }
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    if (!control || !form) {
      return false;
    }

    const newPassword = form.form.get('newPassword');
    const confirmPassword = form.form.get('confirmPassword');

    if (newPassword && confirmPassword && newPassword.value !== confirmPassword.value) {
      form.form.setErrors({ 'notMatched': true });
      return true;
    }

    return false;
  }
}

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  saveForm!: UntypedFormGroup;
  matcher = new MyErrorStateMatcher();
  submitted = false;
  currentUser: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    public utilService: UtilService,
    private userService: UserMgtService,
    private router: Router,
    private sessionService: SessionService
  ) {
    if (this.sessionService.getSession() && this.sessionService.getSession().data && this.sessionService.getSession().data.user) {
      this.currentUser = this.sessionService.getSession();
      console.log(this.currentUser);
    }
  }


  ngOnInit(): void {
    this.saveForm = this.formBuilder.group({
      email: [''],
      newPassword: ["", [Validators.required, Validators.pattern(this.utilService.passwordPattern)]],
      currentPassword: ["", [Validators.required]],
      confirmPassword: ["", [Validators.required, Validators.pattern(this.utilService.passwordPattern)]],
    }, { validators: this.passwordsMatchValidator });

  }

  passwordsMatchValidator(form: FormGroup) {
    const newPassword = form.get('newPassword')?.value;
    const confirmPassword = form.get('confirmPassword')?.value;

    return newPassword === confirmPassword ? null : { 'notMatched': true };
  }


  onSubmit() {
    this.submitted = true;
    this.saveForm.controls['email'].setValue(this.currentUser.data.user.email);
    this.userService.saveAssetWithPath(this.saveForm.value, environment.authUrl + 'auth/change-password')
      .subscribe(
        data => {
          this.submitted = false;
          this.utilService.showNotification(NOTIFICATION_COLOR.success, "Password changed successfully", "bottom", "center");
          this.sessionService.logout();

        },
        error => {
          this.submitted = false;
        }
      )
  }



  checkValidations(control: any, type: any) {
    switch (type) {
      case 'special-character':
        return /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(control.value);;
      case 'number':
        return /\d/.test(control.value);
      case 'lowercase':
        return /[a-z]/.test(control.value);
      case 'uppercase':
        return /[A-Z]/.test(control.value);
      case 'length':
        return control.value.length >= 8 && control.value.length <= 16;
      default:
        return false
    }
  }

}
