<!-- <div class="stepper-content" style="margin-top: 2rem;">
    <div class="step-panel"> -->
<!-- [formGroup]="userForm" (ngSubmit)="onSubmit('')" -->
<form [formGroup]="userForm" (ngSubmit)="onSubmit('')" autocomplete="off">
    <div class="step-panel_header">
        <h2>Other Information</h2>
    </div>
    <div class="step-panel_body">
        <div class="form-set">
            <div class="form-input" *ngIf="this.pageData.permitType.code === 'RFWI'">
                <label>Do You Have The Occupancy?</label>
                <div>
                    <select name="doYouHaveTheOccupancy" id="doYouHaveTheOccupancy"
                        formControlName="doYouHaveTheOccupancy" (change)="focusOutFunction()">
                        <option *ngFor="let op of yesOrNo" [value]="op.id">{{op.name}}</option>
                    </select>
                </div>
            </div>
            <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'">
                <label>First Aid boxes?</label>
                <div>
                    <select name="isFastAidBox" id="isFastAidBox" formControlName="isFastAidBox"
                        (change)="focusOutFunction()">
                        <option *ngFor="let op of yesOrNo" [value]="op.id">{{op.name}}</option>
                    </select>
                </div>
            </div>
            <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'">
                <label>Disability toilets flip-up grab bars?</label>
                <div>
                    <select name="disabilityToiletsFlipUpGrabBars" id="disabilityToiletsFlipUpGrabBars"
                        formControlName="disabilityToiletsFlipUpGrabBars" (change)="focusOutFunction()">
                        <option *ngFor="let op of yesOrNo" [value]="op.id">{{op.name}}</option>
                    </select>
                </div>
            </div>
            <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'">
                <label>Para-lightening system</label>
                <div>
                    <select name="paraLighteningSystem" id="paraLighteningSystem" formControlName="paraLighteningSystem"
                        (change)="focusOutFunction()">
                        <option *ngFor="let op of yesOrNo" [value]="op.id">{{op.name}}</option>
                    </select>
                </div>
            </div>
            <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'">
                <label>Equipment Capacity</label>
                <div>
                    <select name="equipmentCapacity" id="equipmentCapacity" formControlName="equipmentCapacity"
                        (change)="focusOutFunction()">
                        <option *ngFor="let op of equipmentCapacities" [value]="op.id">{{op.name}}</option>
                    </select>
                </div>
            </div>
            <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'">
                <label>Construction method(materials)</label>
                <div>
                    <select name="constructionMethod" id="constructionMethod" formControlName="constructionMethod"
                        (change)="focusOutFunction()">
                        <option *ngFor="let op of constructionMethods" [value]="op.id">{{op.name}}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div class="step-panel_header" *ngIf="this.pageData.permitType.code === 'OCP'">
        <h2>Disaster prevention</h2>
    </div>
    <div class="step-panel_body">
        <div class="form-set">
            <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'">
                <label>A fire alarm system with an alarm bell on each</label>
                <div>
                    <select name="fireAlarmSystemWithAnAlarmBellOnEach" id="fireAlarmSystemWithAnAlarmBellOnEach"
                        formControlName="fireAlarmSystemWithAnAlarmBellOnEach" (change)="focusOutFunction()">
                        <option *ngFor="let op of yesOrNo" [value]="op.id">{{op.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'
        && userForm.value.fireAlarmSystemWithAnAlarmBellOnEach == 'No'">
            <label>Why not fire alarm system with an alarm bell on each</label>
            <div>
                <textarea id="whyNotFireAlarmSystemWithAnAlarmBellOnEach"
                    name="whyNotFireAlarmSystemWithAnAlarmBellOnEach"
                    formControlName="whyNotFireAlarmSystemWithAnAlarmBellOnEach" cols="30" rows="5"
                    (focusout)="focusOutFunction()"></textarea>
            </div>
        </div>
        <div class="form-set">
            <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'">
                <label>Fire extinguishers every 50m on each floor</label>
                <div>
                    <select name="fireExtinguishersEvery50mOnEachFloor" id="fireExtinguishersEvery50mOnEachFloor"
                        formControlName="fireExtinguishersEvery50mOnEachFloor" (change)="focusOutFunction()">
                        <option *ngFor="let op of yesOrNo" [value]="op.id">{{op.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-input"
            *ngIf="this.pageData.permitType.code === 'OCP' && userForm.value.fireExtinguishersEvery50mOnEachFloor == 'No'">
            <label>Why not fire extinguishers every 50m on each floor</label>
            <div>
                <textarea id="whyNotFireExtinguishersEvery50mOnEachFloor"
                    name="whyNotFireExtinguishersEvery50mOnEachFloor"
                    formControlName="whyNotFireExtinguishersEvery50mOnEachFloor" cols="30" rows="5"
                    (focusout)="focusOutFunction()"></textarea>
            </div>
        </div>
        <div class="form-set">
            <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'">
                <label>Functioning exit signs on each floor</label>
                <div>
                    <select name="functioningExitSignsOnEachFloor" id="functioningExitSignsOnEachFloor"
                        formControlName="functioningExitSignsOnEachFloor" (change)="focusOutFunction()">
                        <option *ngFor="let op of yesOrNo" [value]="op.id">{{op.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-input"
            *ngIf="this.pageData.permitType.code === 'OCP' && userForm.value.functioningExitSignsOnEachFloor == 'No'">
            <label>Why not Functioning exit signs on each floor</label>
            <div>
                <textarea id="whyNotfunctioningExitSignsOnEachFloor" name="whyNotfunctioningExitSignsOnEachFloor"
                    formControlName="whyNotfunctioningExitSignsOnEachFloor" cols="30" rows="5"
                    (focusout)="focusOutFunction()"></textarea>
            </div>
        </div>
        <div class="form-set">
            <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'">
                <label>An emergency exit on each floor</label>
                <div>
                    <select name="anEmergencyExitOnEachFloor" id="anEmergencyExitOnEachFloor"
                        formControlName="anEmergencyExitOnEachFloor" (change)="focusOutFunction()">
                        <option *ngFor="let op of yesOrNo" [value]="op.id">{{op.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-input"
            *ngIf="this.pageData.permitType.code === 'OCP' && userForm.value.anEmergencyExitOnEachFloor == 'No'">
            <label>An emergency exit on each floor</label>
            <div>
                <textarea id="whyNotanEmergencyExitOnEachFloor" name="whyNotanEmergencyExitOnEachFloor"
                    formControlName="whyNotanEmergencyExitOnEachFloor" cols="30" rows="5"
                    (focusout)="focusOutFunction()"></textarea>
            </div>
        </div>
        <div class="form-set">
            <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'">
                <label>Floor plan on each level</label>
                <div>
                    <select name="floorPlanOnEachLevel" id="floorPlanOnEachLevel" formControlName="floorPlanOnEachLevel"
                        (change)="focusOutFunction()">
                        <option *ngFor="let op of yesOrNo" [value]="op.id">{{op.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-input"
            *ngIf="this.pageData.permitType.code === 'OCP' && userForm.value.floorPlanOnEachLevel == 'No'">
            <label>Why not Floor plan on each level</label>
            <div>
                <textarea id="whyNotfloorPlanOnEachLevel" name="whyNotfloorPlanOnEachLevel"
                    formControlName="whyNotfloorPlanOnEachLevel" cols="30" rows="5"
                    (focusout)="focusOutFunction()"></textarea>
            </div>
        </div>
        <div class="form-set">
            <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'">
                <label>Number sign on each floor</label>
                <div>
                    <select name="numberSignOnEachFloor" id="numberSignOnEachFloor"
                        formControlName="numberSignOnEachFloor" (change)="focusOutFunction()">
                        <option *ngFor="let op of yesOrNo" [value]="op.id">{{op.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-input"
            *ngIf="this.pageData.permitType.code === 'OCP' && userForm.value.numberSignOnEachFloor == 'No'">
            <label>Why not Number sign on each floor</label>
            <div>
                <textarea id="whyNotnumberSignOnEachFloor" name="whyNotnumberSignOnEachFloor"
                    formControlName="whyNotnumberSignOnEachFloor" cols="30" rows="5"
                    (focusout)="focusOutFunction()"></textarea>
            </div>
        </div>
        <div class="form-set">
            <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'">
                <label>Sign forbidding the use of elevators in case of fire</label>
                <div>
                    <select name="signForbiddingTheUseOfElevatorsInCaseOfFire"
                        id="signForbiddingTheUseOfElevatorsInCaseOfFire"
                        formControlName="signForbiddingTheUseOfElevatorsInCaseOfFire" (change)="focusOutFunction()">
                        <option *ngFor="let op of yesOrNo" [value]="op.id">{{op.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-input"
            *ngIf="this.pageData.permitType.code === 'OCP' && userForm.value.signForbiddingTheUseOfElevatorsInCaseOfFire == 'No'">
            <label>Why not Sign forbidding the use of elevators in case of fire</label>
            <div>
                <textarea id="whyNotsignForbiddingTheUseOfElevatorsInCaseOfFire"
                    name="whyNotsignForbiddingTheUseOfElevatorsInCaseOfFire"
                    formControlName="whyNotsignForbiddingTheUseOfElevatorsInCaseOfFire" cols="30" rows="5"
                    (focusout)="focusOutFunction()"></textarea>
            </div>
        </div>
        <div class="form-set">
            <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'">
                <label>Landing space on top of the building, for helicopters to evacuate people in case there’s a
                    problem down: YES / NO ( if not why)</label>
                <div>
                    <select name="landingSpaceOnTopOfTheBuildingForHelicopters"
                        id="landingSpaceOnTopOfTheBuildingForHelicopters"
                        formControlName="landingSpaceOnTopOfTheBuildingForHelicopters" (change)="focusOutFunction()">
                        <option *ngFor="let op of yesOrNo" [value]="op.id">{{op.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-input"
            *ngIf="this.pageData.permitType.code === 'OCP' && userForm.value.landingSpaceOnTopOfTheBuildingForHelicopters == 'No'">
            <label>Landing space on top of the building, for helicopters to evacuate people in case there’s a problem
                down ( if not why)</label>
            <div>
                <textarea id="whyNotlandingSpaceOnTopOfTheBuildingForHelicopters"
                    name="whyNotlandingSpaceOnTopOfTheBuildingForHelicopters"
                    formControlName="whyNotlandingSpaceOnTopOfTheBuildingForHelicopters" cols="30" rows="5"
                    (focusout)="focusOutFunction()"></textarea>
            </div>
        </div>
        <div class="step-panel_header" *ngIf="this.pageData.permitType.code === 'OCP'">
            <h2>Terror attacks</h2>
        </div>
        <div class="form-set">
            <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'">
                <label>CCTV cameras</label>
                <div>
                    <select name="CCTVCameras" id="CCTVCameras" formControlName="CCTVCameras"
                        (change)="focusOutFunction()">
                        <option *ngFor="let op of yesOrNo" [value]="op.id">{{op.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'
         && userForm.value.CCTVCameras == 'No'">
            <label>CCTV cameras ( if not why)</label>
            <div>
                <textarea id="whyNotCCTVCameras" name="whyNotCCTVCameras" formControlName="whyNotCCTVCameras" cols="30"
                    rows="5" (focusout)="focusOutFunction()"></textarea>
            </div>
        </div>
        <div class="form-set">
            <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'">
                <label>Walk through and held metal detect</label>
                <div>
                    <select name="WalkThroughAndHeldMetalDetect" id="WalkThroughAndHeldMetalDetect"
                        formControlName="WalkThroughAndHeldMetalDetect" (change)="focusOutFunction()">
                        <option *ngFor="let op of yesOrNo" [value]="op.id">{{op.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'
        && userForm.value.WalkThroughAndHeldMetalDetect == 'No'">
            <label>Walk through and held metal detect ( if not why)</label>
            <div>
                <textarea id="whyNotWalkThroughAndHeldMetalDetect" name="whyNotWalkThroughAndHeldMetalDetect"
                    formControlName="whyNotWalkThroughAndHeldMetalDetect" cols="30" rows="5"
                    (focusout)="focusOutFunction()"></textarea>
            </div>
        </div>
        <div class="form-set">
            <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'">
                <label>Under search mirror</label>
                <div>
                    <select name="UnderSearchMirror" id="UnderSearchMirror" formControlName="UnderSearchMirror"
                        (change)="focusOutFunction()">
                        <option *ngFor="let op of yesOrNo" [value]="op.id">{{op.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'
        && userForm.value.UnderSearchMirror == 'No'">
            <label>Under search mirror ( if not why)</label>
            <div>
                <textarea id="whyNotUnderSearchMirror" name="whyNotUnderSearchMirror"
                    formControlName="whyNotUnderSearchMirror" cols="30" rows="5"
                    (focusout)="focusOutFunction()"></textarea>
            </div>
        </div>
        <div class="form-set">
            <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'">
                <label>Luggage scanners</label>
                <div>
                    <select name="LuggageScanners" id="LuggageScanners" formControlName="LuggageScanners"
                        (change)="focusOutFunction()">
                        <option *ngFor="let op of yesOrNo" [value]="op.id">{{op.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'
        && userForm.value.LuggageScanners == 'No'">
            <label>Luggage scanners ( if not why)</label>
            <div>
                <textarea id="whyNotLuggageScanners" name="whyNotLuggageScanners"
                    formControlName="whyNotLuggageScanners" cols="30" rows="5"
                    (focusout)="focusOutFunction()"></textarea>
            </div>
        </div>
        <div class="form-set">
            <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'">
                <label>Plates indicating emergency response units, phone numbers</label>
                <div>
                    <select name="PlatesIndicatingEmergencyResponseUnitsPhoneNumbers"
                        id="PlatesIndicatingEmergencyResponseUnitsPhoneNumbers"
                        formControlName="PlatesIndicatingEmergencyResponseUnitsPhoneNumbers"
                        (change)="focusOutFunction()">
                        <option *ngFor="let op of yesOrNo" [value]="op.id">{{op.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'
         && userForm.value.PlatesIndicatingEmergencyResponseUnitsPhoneNumbers == 'No'">
            <label>Plates indicating emergency response units, phone numbers ( if not why)</label>
            <div>
                <textarea id="whyNotPlatesIndicatingEmergencyResponseUnitsPhoneNumbers"
                    name="whyNotPlatesIndicatingEmergencyResponseUnitsPhoneNumbers"
                    formControlName="whyNotPlatesIndicatingEmergencyResponseUnitsPhoneNumbers" cols="30" rows="5"
                    (focusout)="focusOutFunction()"></textarea>
            </div>
        </div>
        <div class="form-set">
            <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'">
                <label>Emergency evacuation plan</label>
                <div>
                    <select name="EmergencyEvacuationPlan" id="EmergencyEvacuationPlan"
                        formControlName="EmergencyEvacuationPlan" (change)="focusOutFunction()">
                        <option *ngFor="let op of yesOrNo" [value]="op.id">{{op.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'
        && userForm.value.EmergencyEvacuationPlan == 'No'">
            <label>Emergency evacuation plan ( if not why)</label>
            <div>
                <textarea id="whyNotEmergencyEvacuationPlan" name="whyNotEmergencyEvacuationPlan"
                    formControlName="whyNotEmergencyEvacuationPlan" cols="30" rows="5"
                    (focusout)="focusOutFunction()"></textarea>
            </div>
        </div>
        <div class="form-set">
            <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'">
                <label>Security manager and staff cameras</label>
                <div>
                    <select name="SecurityManagerAndStaffCameras" id="SecurityManagerAndStaffCameras"
                        formControlName="SecurityManagerAndStaffCameras" (change)="focusOutFunction()">
                        <option *ngFor="let op of yesOrNo" [value]="op.id">{{op.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP' &&
        userForm.value.SecurityManagerAndStaffCameras == 'No'">
            <label>Security manager and staff cameras ( if not why)</label>
            <div>
                <textarea id="whyNotSecurityManagerAndStaffCameras" name="whyNotSecurityManagerAndStaffCameras"
                    formControlName="whyNotSecurityManagerAndStaffCameras" cols="30" rows="5"
                    (focusout)="focusOutFunction()"></textarea>
            </div>
        </div>
        <div class="form-set">
            <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'">
                <label>An internal communication system</label>
                <div>
                    <select name="AnInternalCommunicationSystem" id="AnInternalCommunicationSystem"
                        formControlName="AnInternalCommunicationSystem" (change)="focusOutFunction()">
                        <option *ngFor="let op of yesOrNo" [value]="op.id">{{op.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'
        && userForm.value.AnInternalCommunicationSystem == 'No'">
            <label>An internal communication system ( if not why)</label>
            <div>
                <textarea id="whyNotAnInternalCommunicationSystem" name="whyNotAnInternalCommunicationSystem"
                    formControlName="whyNotAnInternalCommunicationSystem" (focusout)="focusOutFunction()" cols="30"
                    rows="5"></textarea>
            </div>
        </div>
        <div class="form-set">
            <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'">
                <label>Broad band( internet services)</label>
                <div>
                    <select name="BroadBandInternetServices" id="BroadBandInternetServices"
                        formControlName="BroadBandInternetServices" (change)="focusOutFunction()">
                        <option *ngFor="let op of yesOrNo" [value]="op.id">{{op.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'
        && userForm.value.BroadBandInternetServices == 'No'">
            <label>Broad band( internet services) ( if not why)</label>
            <div>
                <textarea id="whyNotBroadBandInternetServices" name="whyNotBroadBandInternetServices"
                    formControlName="whyNotBroadBandInternetServices" cols="30" rows="5"
                    (focusout)="focusOutFunction()"></textarea>
            </div>
        </div>
        <div class="form-set">
            <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP'">
                <label>Staff and visitor’s access cards</label>
                <div>
                    <select name="StaffAndVisitorAccessCards" id="StaffAndVisitorAccessCards"
                        (change)="focusOutFunction()" formControlName="StaffAndVisitorAccessCards">
                        <option *ngFor="let op of yesOrNo" [value]="op.id">{{op.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-input" *ngIf="this.pageData.permitType.code === 'OCP' &&
        userForm.value.StaffAndVisitorAccessCards == 'No'">
            <label>Staff and visitor’s access cards ( if not why)</label>
            <div>
                <textarea id="whyNotStaffAndVisitorAccessCards" name="whyNotStaffAndVisitorAccessCards"
                    formControlName="whyNotStaffAndVisitorAccessCards" (focusout)="focusOutFunction()" cols="30"
                    rows="5"></textarea>
            </div>
        </div>
        <div class="form-input" *ngIf="this.pageData.permitType.code === 'PMP'">
            <label>Application for fixed telephone line connection</label>
            <div>
                <textarea id="applicationForFixedTelephoneLineConnection"
                    name="applicationForFixedTelephoneLineConnection"
                    formControlName="applicationForFixedTelephoneLineConnection" (focusout)="focusOutFunction()"
                    cols="30" rows="5"></textarea>
            </div>
        </div>
    </div>
    <div class="step-panel_header" *ngIf="this.pageData.permitType.code === 'PMP'">
        <h2>Facilities for persons with disabilities</h2>
    </div>
    <div class="form-set">
        <div class="form-input" *ngIf="this.pageData.permitType.code === 'PMP'">
            <label>Are there any facilities for the disabled provided in this building (PWD sensitive)</label>
            <div>
                <select name="areThereAnyFacilitiesForTheDisabledProvidedBuilding"
                    id="areThereAnyFacilitiesForTheDisabledProvidedBuilding"
                    formControlName="areThereAnyFacilitiesForTheDisabledProvidedBuilding"
                    (focusout)="focusOutFunction()">
                    <option *ngFor="let op of yesOrNo" [value]="op.id">{{op.name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="form-input" *ngIf="this.pageData.permitType.code === 'PMP'
     &&
     userForm.value.areThereAnyFacilitiesForTheDisabledProvidedBuilding == 'No'">
        <label>Are there any facilities for the disabled provided in this building (PWD sensitive) ( if not why)</label>
        <div>
            <textarea id="whyNotAreThereAnyFacilitiesForTheDisabledProvidedBuilding"
                name="whyNotAreThereAnyFacilitiesForTheDisabledProvidedBuilding"
                formControlName="whyNotAreThereAnyFacilitiesForTheDisabledProvidedBuilding"
                (focusout)="focusOutFunction()" cols="30" rows="5"></textarea>
        </div>
    </div>
    <div class="form-input" *ngIf="this.pageData.permitType.code === 'FINS'">
        <label>Date for requested inspection<span>*</span></label>
        <div>
            <input type="date" id="dateForRequestedInspection" name="dateForRequestedInspection"
                formControlName="dateForRequestedInspection" (focusout)="focusOutFunction()">
        </div>
    </div>
    <div class="form-set">
        <div class="form-input" *ngIf="this.pageData.permitType.code === 'FINS'">
            <label>User Type</label>
            <div>
                <select name="userTypeId" id="userTypeId" formControlName="userTypeId" (focusout)="enableUserType()"
                (focusout)="focusOutAndFindUser()">
                    <option *ngFor="let op of accountTypes" [value]="op.id">{{op.name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="form-input" *ngIf="this.pageData.permitType.code === 'FINS'">
        <label>License Number<span>*</span></label>
        <div>
            <input type="text" id="licenseNumber" name="licenseNumber" formControlName="licenseNumber"
                (focusout)="focusOutAndFindUser()">
        </div>
    </div>
    <div class="form-input" *ngIf="this.pageData.permitType.code === 'FINS'">
        <label>Supervising firm site engineer<span> *</span></label>
        <div>
            <input type="text" id="supervisingFirmSiteEngineer" name="supervisingFirmSiteEngineer"
                formControlName="supervisingFirmSiteEngineer" (focusout)="focusOutFunction()"
                readonly>
        </div>
    </div>
    <div class="form-input" *ngIf="this.pageData.permitType.code === 'FINS'">
        <label>Remarks</label>
        <div>
            <textarea id="remarks" name="remarks" formControlName="remarks" (focusout)="focusOutFunction()" cols="30"
                rows="5"></textarea>
        </div>
    </div>
    <div class="form-input" *ngIf="this.pageData.permitType.code === 'FINS'">
        <label>Stage of construction</label>
        <div>
            <textarea id="whyNotAreThereAnyFacilitiesForTheDisabledProvidedBuilding"
                name="whyNotAreThereAnyFacilitiesForTheDisabledProvidedBuilding"
                formControlName="whyNotAreThereAnyFacilitiesForTheDisabledProvidedBuilding"
                (focusout)="focusOutFunction()" cols="30" rows="5"></textarea>
        </div>
    </div>
    <!-- Required Documents list -->
    <div class="kbk-updloadtable">
        <ul class="updload-list">
            <!-- <div class="updload-list_header">
                        <li class="updload-list_item">
                            <div class="updload-list_item-dt">
                                <span class="text">Required Documents <a href="assets/files/BOQ-OF-REVIEW.xlsx">Click
                                        here to download the BOQ file</a></span>
                            </div>
                            <div class="updload-list_item-dt">
                                <span class="text">Uploaded Documents</span>
                            </div>
                        </li>
                    </div> -->
        </ul>
    </div>
    <div class="step-panel_footer">
        <!-- <button class="kbk-btn kbk-btn-main" type="button" *ngIf="submitted"> Wait... </button> -->
        <!-- <button class="kbk-btn kbk-btn-main" data-bs-toggle="modal" type="button" data-bs-target="#showModal"
                    *ngIf="isReadyToSubmit && !submitted" (click)="openModal(isAcceptThatTheInformationProvided, 'md')">
                    Submit </button> -->
    </div>
</form>
<!-- </div>
</div> -->