<div class="login-container">
    <div class="login-content">
        <div class="illust">
            <img class="logo" [routerLink]="['/']" src="assets/ikons/logo/NavLogo.svg" alt="">
            <img class="ill" src="assets/imgs/SVG/illustration.svg" alt="">
        </div>
        <div class="dtials">
            <form class="form" [formGroup]="saveForm" (ngSubmit)="onSubmit()">
                <div class="form-header">
                    <h2 class="display-md">Verify Code</h2>
                </div>
                <div class="form-body">
                    <div class="form-input">
                        <label>Code</label>
                        <div>
                            <!-- <input class="flg" type="text" id="code" name="code" formControlName="code" [readonly]="!isFailedToSubmit"
                            required /> -->
                            <input class="flg" type="number" id="code" name="code" formControlName="code" required />
                        </div>
                    </div>
                </div>
                <div class="form-footer">
                    <!-- <button *ngIf="isFailedToSubmit" class="kbk-btn kbk-btn-lg kbk-btn-main"
                        [disabled]="saveForm.invalid" type="submit">Submit</button>
                    <button *ngIf="!isFailedToSubmit" class="kbk-btn kbk-btn-lg kbk-btn-main"
                        type="button">Verifying..</button> -->
                    <button *ngIf="!submitted" class="kbk-btn kbk-btn-lg kbk-btn-main" [disabled]="saveForm.invalid"
                        type="submit">Submit</button>
                    <button *ngIf="submitted" class="kbk-btn kbk-btn-lg kbk-btn-main"
                        type="button">Verifying..</button>
                </div>
            </form>
        </div>
    </div>
</div>